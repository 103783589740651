import React, { useState, useEffect, useMemo } from "react";
import {
  Flex,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Input,
  Button,
  FormControl,
  FormLabel,
  Select,
  useToast,
  Heading,
} from "@chakra-ui/react";
import { useUserContext } from "../context/UserContext";
import CountrySelect from "./form/CountrySelect";
import ProfileImageUploader from "./form/ProfileImageUploader";
import { isEqual } from "lodash"; // Importer isEqual

function EditProfileDrawer({ isOpen, onClose, handleLogout }) {
  
  const { currentUser, updateUserProfile } = useUserContext();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    profileImage: "",
    country: "",
    day: "",
    month: "",
    year: "",
  });

  const [loading, setLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false); // État pour suivre les changements
  const toast = useToast();

  const months = useMemo(
    () => [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    []
  );

  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const years = Array.from({ length: 57 }, (_, i) => 1960 + i); // From 1960 to 2016

  useEffect(() => {
    if (currentUser) {
      const [year, month, day] =
        currentUser.birthday && typeof currentUser.birthday === "string"
          ? currentUser.birthday.split("-")
          : ["", "", ""];
  
      setFormData({
        firstName: currentUser.firstName || "",
        lastName: currentUser.lastName || "",
        profileImage: currentUser.profileImage || "",
        country: currentUser.country || "",
        countryCode: currentUser.countryCode || "",
        day: day ? parseInt(day, 10) : "",
        month:
          month && parseInt(month, 10) >= 1 && parseInt(month, 10) <= 12
            ? months[parseInt(month, 10) - 1]
            : "",
        year: year || "",
      });
    }
  }, [currentUser, months]);


    useEffect(() => {
      if (currentUser) {
        const [year, month, day] =
          currentUser.birthday && typeof currentUser.birthday === "string"
            ? currentUser.birthday.split("-")
            : ["", "", ""];

        const initialData = {
          firstName: currentUser.firstName || "",
          lastName: currentUser.lastName || "",
          profileImage: currentUser.profileImage || "",
          country: currentUser.country || "",
          countryCode: currentUser.countryCode || "",
          day: day ? parseInt(day, 10) : "",
          month:
            month && parseInt(month, 10) >= 1 && parseInt(month, 10) <= 12
              ? months[parseInt(month, 10) - 1]
              : "",
          year: year || "",
        };

        // Comparer l'état initial avec formData
        setIsChanged(!isEqual(initialData, formData));
      }
    }, [currentUser, formData, months]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    setLoading(true);
  
    try {
      // Créer une date de naissance dans le format attendu (YYYY-MM-DD)
      const birthday = `${formData.year}-${String(
        months.indexOf(formData.month) + 1
      ).padStart(2, "0")}-${String(formData.day).padStart(2, "0")}`;
  
      // Construire les données mises à jour
      const updatedData = {
        ...formData,
        birthday, // Ajouter la date de naissance combinée
      };
  
      // Supprimer les champs inutiles (day, month, year)
      delete updatedData.day;
      delete updatedData.month;
      delete updatedData.year;
  
      // Mettre à jour le profil utilisateur
      await updateUserProfile(updatedData);
  
      toast({
        title: "Profile Updated",
        description: "Your profile has been updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
  
      onClose();
    } catch (error) {
      console.error("Error updating profile:", error.message);
      toast({
        title: "Error",
        description: "Failed to update your profile.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
      <DrawerHeader>
          <Heading>Edit profile</Heading>
        </DrawerHeader>
        <DrawerBody gap={8} as={Flex} flexDirection={"column"}>
          <ProfileImageUploader
            value={formData.profileImage}
            onChange={(newImage) =>
              setFormData((prev) => ({ ...prev, profileImage: newImage }))
            }
            storagePath={`profileImages/${currentUser?.uid}`}
          />
          <FormControl>
            <FormLabel>First Name</FormLabel>
            <Input
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              placeholder="Enter your first name"
            />
          </FormControl>
          <FormControl>
            <FormLabel>Last Name</FormLabel>
            <Input
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              placeholder="Enter your last name"
            />
          </FormControl>
          <FormControl>
            <FormLabel>Country</FormLabel>
            <CountrySelect
              value={
                formData.countryCode
                  ? {
                      label: formData.country,
                      value: formData.countryCode,
                      flagCode: formData.countryCode.toLowerCase(),
                    }
                  : null
              }
              onChange={(option) =>
                setFormData((prev) => ({
                  ...prev,
                  country: option?.label || "",
                  countryCode: option?.value || "",
                }))
              }
              isDisabled={loading}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Birthday</FormLabel>
            <Flex gap={4}>
              <Select
                name="day"
                value={formData.day}
                onChange={handleInputChange}
                placeholder="Day"
                flex={1}
              >
                {days.map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </Select>
              <Select
                name="month"
                value={formData.month}
                onChange={handleInputChange}
                placeholder="Month"
                flex={2}
              >
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </Select>
              <Select
                name="year"
                value={formData.year}
                onChange={handleInputChange}
                placeholder="Year"
                flex={1}
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
            </Flex>
          </FormControl>
        </DrawerBody>
        <DrawerFooter gap={2}>
          <Button onClick={handleLogout} isDisabled={loading} variant="ghost">
            Logout
          </Button>
          <Button onClick={onClose} isDisabled={loading} variant="ghost">
            Cancel
          </Button>
          <Button
            bg={"lightGreen.300"}
            isLoading={loading}
            onClick={handleSave}
            isDisabled={!isChanged || loading} // Désactivé si pas de changements ou en cours de chargement
          >
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export default EditProfileDrawer;
