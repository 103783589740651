const useColors = () => {
    const colors = {
      brand: {
        25: "#F5FAFF",
        50: "#EFF8FF",
        100: "#D1E9FF",
        200: "#B2DDFF",
        300: "#84CAFF",
        400: "#53B1FD",
        500: "#2E90FA",
        600: "#1570EF",
        700: "#175CD3",
        800: "#1849A9",
        900: "#194185",
        950: "#102A56",
      },      
      grey: {
        25: '#FCFCFD',
        50: '#F8FAFC',
        100: '#EEF2F6',
        200: '#E3E8EF',
        300: '#CDD5DF',
        400: '#9AA4B2',
        500: '#697586',
        600: '#4B5565',
        700: '#364152',
        800: '#202939',
        900: '#121926',
        950: '#0D121C'
      },
      error: {
        25: "#FFF8FA",
        50: "#FDE3F2",
        100: "#FEE4E2",
        200: "#FECFCD",
        300: "#FDA29B",
        400: "#F97068",
        500: "#F04438",
        600: "#D92D20",
        700: "#B42318",
        800: "#912018",
        900: "#7A271A",
        950: "#55160C",
      },
      lightGreen: {
        25: "#FAFEF5",
        50: "#F3FEE7",
        100: "#E4FBCC",
        200: "#D0F6AB",
        300: "#A6EF67",
        400: "#85E13A",
        500: "#66C81C",
        600: "#4CA30D",
        700: "#387C0F",
        800: "#326212",
        900: "#285314",
        950: "#15290A",
      },
      
      success: {
        25: "#F6FEF9",
        50: "#ECFDF3",
        100: "#D1FADF",
        200: "#A6F4C5",
        300: "#75E0A7",
        400: "#4CC38A",
        500: "#1EA977",
        600: "#079455",
        700: "#067E67",
        800: "#05603A",
        900: "#074D31",
        950: "#053221",
      },
      warning: {
        25: "#FFFCF5",
        50: "#FFF7EB",
        100: "#FEF0C7",
        200: "#FEDF89",
        300: "#FEC84B",
        400: "#FDB022",
        500: "#F79009",
        600: "#DC6803",
        700: "#B54708",
        800: "#93370D",
        900: "#7A2E0E",
        950: "#4E1D09",
      },
    };
  
    return {
      colors,
    };
  };
  
  export default useColors;