import { Bell } from "lucide-react";
import {
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  Box,
  Text,
  Badge,
  Button,
  VStack,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import useNotifications from "../../hooks/useNotifications";
import { useNavigate } from "react-router-dom";
import useMatchActions from "../../hooks/useMatchActions";

function NotificationBell() {
  const { notifications, markNotificationAsRead } = useNotifications();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { handleJoinMatch } = useMatchActions();

  // Compter le nombre de notifications non lues
  const unreadCount = notifications.filter(
    (notification) => !notification.read
  ).length;

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Box position="relative">
          <IconButton
            aria-label="Notifications"
            icon={<Bell />}
            variant="ghost"
          />
          {unreadCount > 0 && (
            <Badge
              colorScheme="red"
              variant={"solid"}
              borderRadius="full"
              position="absolute"
              top="1"
              right="1"
              px="2"
              py="0.5"
              fontSize="2xs"
              zIndex={2}>
              {unreadCount}
            </Badge>
          )}
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader>Notifications</PopoverHeader>
        <PopoverBody>
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <Box
                key={notification.id}
                p={4}
                borderBottom="1px solid"
                borderColor="grey.800">
                <VStack align="start" spacing={4}>
                  
                  <Text color={"white"} fontSize={"sm"}>{notification.message}</Text>
                  
                  {notification.type === "MATCH_CREATED" &&
                    notification.joinable && (
                      <Flex gap={1}>
                        <Button
                          size="sm"
                          bg="lightGreen.300"
                          onClick={() => {
                            handleJoinMatch({
                              groupId: notification.groupId,
                              matchId: notification.matchId,
                            });
                            navigate(
                              `/groups/${notification.groupId}/match/${notification.matchId}`
                            );
                            markNotificationAsRead(notification.id);
                          }}>
                          Join
                        </Button>

                        <Button
                          size="sm"
                          variant={"outline"}
                          onClick={() =>
                            markNotificationAsRead(notification.id)
                          }>
                          Decline
                        </Button>
                      </Flex>
                    )}
                </VStack>
              </Box>
            ))
          ) : (
            <Text>No new notifications</Text>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default NotificationBell;
