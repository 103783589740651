import { useEffect, useState } from 'react';
import { db, auth } from '../firebaseConfig';
import { collection, query, where, onSnapshot, updateDoc, doc } from 'firebase/firestore';

function useNotifications() {
  const [notifications, setNotifications] = useState([]);
  const userId = auth.currentUser?.uid;

  useEffect(() => {
    if (!userId) return;

    const notificationsRef = collection(db, 'players', userId, 'notifications');
    const q = query(notificationsRef, where('read', '==', false));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const notificationsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNotifications(notificationsData);
    });

    return () => unsubscribe();
  }, [userId]);

  const markNotificationAsRead = async (notificationId) => {
    if (!userId) return;

    const notificationRef = doc(db, 'players', userId, 'notifications', notificationId);
    await updateDoc(notificationRef, { read: true });

    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== notificationId)
    );
  };

  return { notifications, markNotificationAsRead };
}

export default useNotifications;
