import {
  GridItem,
  Flex,
  Badge,
  Heading,
  Text,
  Tag,
  TagLabel,
  HStack,
  Button,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  TagLeftIcon,
} from "@chakra-ui/react";
import { MapPin, User } from "lucide-react";
import { useAppContext } from "../../context/AppContext";
import useMatchActions from "../../hooks/useMatchActions";


function FindAMatchListing({ match }) {
  const { isJoined, canCancel, handleJoinMatch, handleCancelParticipation } = useMatchActions(match);
  const toast = useToast();
  const { formatScheduledTime } = useAppContext();

  // Modal state
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleConfirmJoin = async () => {
    try {
      await handleJoinMatch(); // Call the join match logic
      onClose(); // Close the modal after confirmation
    } catch (error) {
      console.error("Error confirming participation:", error.message);
      toast({
        title: "Error",
        description: "Failed to join the match. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "N/A";
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const registeredPlayers =
    (match?.players?.team1?.length || 0) + (match?.players?.team2?.length || 0);
  const totalPlayers =
    (match?.playerCount?.team1 || 0) + (match?.playerCount?.team2 || 0);

  return (
    <>
      {/* Match Listing */}
      <GridItem key={match.id} bg="grey.800" p={4} position="relative">
        <Flex direction="column" gap={4}>
          {/* Match Details */}
          <Flex gap={4} align="center" position={"relative"}>
            <Badge colorScheme="green" size="sm">
              {formatDate(match.scheduledTime || match.startTime)}
            </Badge>
            <Flex align="center" gap={1}>
              <MapPin size={14} color="#AAA" />
              <Text fontSize="sm" color={"grey.400"}>
                {match.city || "N/A"}
              </Text>
            </Flex>

            {match.endTime && match.playerCount && (
              <Tag>
                <TagLabel>
                  {match?.playerCount?.team1} vs {match?.playerCount?.team2}
                </TagLabel>
              </Tag>
            )}

            {!match.endTime && match.playerCount && (
              <Tag>
                <TagLeftIcon boxSize="12px" as={User} />
                <TagLabel>
                  {registeredPlayers} / {totalPlayers}
                </TagLabel>
              </Tag>
            )}
          </Flex>

          <Flex
            gap={4}
            alignItems={"flex-end"}
            justifyContent={"space-between"}
            flex={1}
          >
            {match.startTime ? (
              <Heading size="2xl" color="white">
                {match.score?.team1 || 0} - {match.score?.team2 || 0}
              </Heading>
            ) : (
              <Heading size="2xl" color="white">
                {formatScheduledTime(match.scheduledTime)}
              </Heading>
            )}

            {/* Buttons */}
            <HStack spacing={2}>
              {!isJoined && (
                <Button onClick={onOpen} bg="lightGreen.300" cursor={"pointer"}>
                  Join Match
                </Button>
              )}
              {isJoined && (
                <Button
                  onClick={handleCancelParticipation}
                  colorScheme="red"
                  isDisabled={!canCancel()}
                  cursor={!canCancel() ? "not-allowed" : "pointer"}
                >
                  Cancel Participation
                </Button>
              )}
            </HStack>
          </Flex>
        </Flex>
      </GridItem>

      {/* Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader><Heading>Confirm Participation</Heading></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text color={"white"}>
              By joining this match, you agree to participate. Please note:
            </Text>
            <Text mt={2}  color={"white"}>
              - You can cancel your participation up to <strong>2 hours</strong>{" "}
              before the match starts.
            </Text>
            <Text mt={2}  color={"white"}>
              - If you fail to attend without cancellation, you may be marked as{" "}
              <strong>NoShow</strong>, which could affect your ability to join
              future matches.
            </Text>
          </ModalBody>
          <ModalFooter gap={2}>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button bg={"lightGreen.300"} onClick={handleConfirmJoin}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default FindAMatchListing;
