import { useUserContext } from "../../context/UserContext";
import Calendar from "../Calendar";

function UserCalendar() {
  
  const { currentUser } = useUserContext();
  const userId = currentUser?.id;
  
  return (
    <Calendar playerId={userId} grid={3} />
  );
}

export default UserCalendar;
