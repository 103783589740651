import React, { useState } from "react";
import countryList from "react-select-country-list";
import Select from "react-select";
import { Flex, Box } from "@chakra-ui/react";

const CountrySelect = ({ onChange, isDisabled, value }) => {
  const [options] = useState(
    countryList().getData().map((country) => ({
      ...country,
      flagCode: country.value.toLowerCase(),
    }))
  );



  return (
    <Box 
    width="100%"
    sx={{
      ".customSelect": {
        width: "100%",
        borderRadius: '0 !important'
      },
      ".customSelect__control": {
        borderColor: "grey.800",
        bg: "grey.800",
        borderRadius: '0 !important',
        h: "48px"
      },
      ".customSelect__input": {
        bg: "transparent !important",
        color: "white !important",
      },
      ".customSelect__single-value": {
        color: "white !important",
      },
      ".customSelect__menu": {
        borderRadius: '0 !important',
        bg: 'grey.800',
        m: 0
      },
      ".customSelect__menu-list": {
        p: 0
      },
      ".customSelect__option:hover": {
        bg: 'brand.500',
      }
    }}>
      <Select
      className="customSelect"
      classNamePrefix="customSelect"
      options={options}
      value={value}
      onChange={(selectedOption) => onChange(selectedOption)}
      isDisabled={isDisabled}
      placeholder="Select your country"
      formatOptionLabel={(country) => (
        <Flex align={"center"} gap={3}>
          <img
            src={`https://flagcdn.com/w40/${country.flagCode}.png`}
            alt={country.label}
            style={{
              width: "20px",
              height: "15px",
            }}
          />
          {country.label}
        </Flex>
      )}
      isSearchable
      isClearable
    />
    </Box>
  );
};

export default CountrySelect;
