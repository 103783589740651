import React, { useState } from "react";
import {
  Heading,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  chakra,
  Image,
  CircularProgress,
  CircularProgressLabel,
  Text
} from "@chakra-ui/react";
import { Outlet} from "react-router-dom";

import EditGroupDrawer from "../Group/EditGroupDrawer";
import { useUserContext } from "../../context/UserContext";
import useUserData from "../../hooks/useUserStats";
import profileBg from "../../img/playerBg.webp";
import JoinGroupModal from "../Group/JoinGroupModal";

function UserLayout() {
  
  const { currentUser, setGroups } = useUserContext();
  const { matchStats } = useUserData(currentUser?.id);

  const winRate =
  matchStats.matchesPlayed > 0
    ? ((matchStats.wins / matchStats.matchesPlayed) * 100).toFixed(2)
    : 0;

const goalsPerGame =
  matchStats.matchesPlayed > 0
    ? (matchStats.goalsScored / matchStats.matchesPlayed).toFixed(2)
    : 0;

/**
 * Calculate the age of the user based on their birthday.
 * @param {string} birthday - The user's birthday in a valid date format.
 * @returns {string} - The calculated age or "N/A" if invalid.
 */
const calculateAge = (birthday) => {
  if (!birthday) return "N/A";

  const birthDate = new Date(birthday);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();

  const monthDifference = today.getMonth() - birthDate.getMonth();
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

// Dynamically calculate age from currentUser data
const userCountryCode = (currentUser?.countryCode || "").toLowerCase();
const age = currentUser?.birthday ? calculateAge(currentUser?.birthday) : "N/A";


const [isGroupDrawerOpen, setIsGroupDrawerOpen] = useState(false);
const [isJoinGroupModalOpen, setIsJoinGroupModalOpen] = useState(false);

const handleGroupCreated = (newGroup) => {
  setGroups((prevGroups) => [...prevGroups, newGroup]);
};

const handleGroupJoined = (newGroup) => {
  setGroups((prevGroups) => [...prevGroups, newGroup]);
};


  return (
    <>
    <Flex
        direction={"column"}
        backgroundImage={profileBg}
        backgroundSize={"cover"}
        backgroundPosition={"center"}
        position={"relative"}
        w={"100%"}>
        <Flex
          px={{ base: 8, md: 16 }}
          py={{ base: 8, md: 8 }}
          justify="space-between"
          align={{ base: "flex-start", md: "flex-start" }}
          direction={{ base: "column", md: "row" }}
          gap={16}>
          <Flex direction={{ base: "column", lg: "row" }} gap={8} w={"100%"}>
            <Flex gap={8} flex={1}>
              <Flex direction="column" gap={6} color="white" zIndex="1">
                <Flex direction={"column"} gap={0}>
                  <Heading size="xl" fontWeight={"500"} lineHeight={1}>
                    {currentUser?.firstName || "First name"}
                  </Heading>
                  <Flex gap={4} align={"center"}>
                    <Heading
                      lineHeight={1}
                      size="3xl"
                      color={"lightGreen.300"}
                      textTransform={"uppercase"}>
                      {currentUser?.lastName || "Last name"}
                    </Heading>
                    <Image
                      src={`https://flagcdn.com/w80/${userCountryCode || "lu"}.png`}
                      style={{ width: "32px", height: "32px" }}
                      borderRadius={"3px"}
                    />
                  </Flex>
                </Flex>
                <Flex direction="row" gap={{ base: 6, md: 8 }}>
                  <Stat>
                    <StatLabel
                      color={"brand.300"}
                      textTransform={"uppercase"}
                      fontSize={"xs"}>
                      Age
                    </StatLabel>
                    <StatNumber fontWeight={"800"}>{age}</StatNumber>
                  </Stat>
                  <Stat>
                    <StatLabel
                      color={"brand.300"}
                      textTransform={"uppercase"}
                      fontSize={"xs"}>
                      Games
                    </StatLabel>
                    <StatNumber fontWeight={"800"}>
                      {matchStats.matchesPlayed}
                    </StatNumber>
                  </Stat>
                  <Stat>
                    <StatLabel
                      color={"brand.300"}
                      textTransform={"uppercase"}
                      fontSize={"xs"}>
                      Wins
                    </StatLabel>
                    <StatNumber fontWeight={"800"}>
                      {matchStats.wins}
                    </StatNumber>
                  </Stat>
                  <Stat>
                    <StatLabel
                      color={"brand.300"}
                      textTransform={"uppercase"}
                      fontSize={"xs"}>
                      Goals
                    </StatLabel>
                    <StatNumber fontWeight={"800"}>
                      {matchStats.goalsScored}
                    </StatNumber>
                  </Stat>
                </Flex>
              </Flex>
            </Flex>

            <Flex
              direction={"row"}
              justify="flex-start"
              align="center"
              gap={{ base: 8, md: 16 }}>
              {/* Win Ratio */}
              <Flex direction="row" align="center" gap={3}>
                <CircularProgress
                  value={winRate}
                  color="lightGreen"
                  size={82}
                  thickness="8px">
                  <CircularProgressLabel
                    color={"white"}
                    fontSize={"sm"}
                    fontWeight={"bold"}>{`${winRate}`}</CircularProgressLabel>
                </CircularProgress>
                <Text
                  fontWeight="bold"
                  fontSize="xs"
                  color={"white"}
                  lineHeight={1.2}>
                  WIN <br />
                  <chakra.span color={"brand.200"}>RATIO</chakra.span>
                </Text>
              </Flex>

              {/* Goals Per Game */}
              <Flex direction="row" align="center" gap={3}>
                <CircularProgress
                  value={goalsPerGame * 100}
                  color="lightGreen"
                  size={82}
                  thickness="8px">
                  <CircularProgressLabel
                    color={"white"}
                    fontSize={"sm"}
                    fontWeight={
                      "bold"
                    }>{`${goalsPerGame}`}</CircularProgressLabel>
                </CircularProgress>
                <Text
                  fontWeight="bold"
                  fontSize="xs"
                  color={"white"}
                  lineHeight={1.2}>
                  GOALS <br />
                  <chakra.span color={"brand.200"}>PER GAME</chakra.span>
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>         
      </Flex>



      <Outlet
        context={{
          setIsJoinGroupModalOpen,
          setIsGroupDrawerOpen,
        }}
      />


      <JoinGroupModal
        isOpen={isJoinGroupModalOpen}
        onClose={() => setIsJoinGroupModalOpen(false)}
        userId={currentUser?.id}
        onGroupJoined={handleGroupJoined}
      />

      <EditGroupDrawer
        isOpen={isGroupDrawerOpen}
        onClose={() => setIsGroupDrawerOpen(false)}
        userId={currentUser?.id}
        onGroupCreated={handleGroupCreated}
      />

      </>

     );
}

export default UserLayout;
