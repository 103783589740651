import React, { useState } from "react";
import {
  Heading,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { Outlet, useParams } from "react-router-dom";
import useGroup from "../../hooks/useGroup";
import { auth } from "../../firebaseConfig";
import EditGroupDrawer from "./EditGroupDrawer";
import { GroupProvider } from "../../context/GroupContext";
import PlayerProfileDrawer from "../PlayerProfileDrawer";
import GroupHeader from "./GroupHeader";

function GroupLayout() {
  const { groupId } = useParams();
  const { group, loading, error, reloadGroup } = useGroup(groupId);

  
  

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    


  const handleOpenEditModal = () => setIsEditModalOpen(true);
  
  const [selectedPlayerId, setSelectedPlayerId] = useState(null);
  const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
  const openPlayerModal = (playerId) => {
    setSelectedPlayerId(playerId);
    setIsPlayerModalOpen(true);
  };

  const closePlayerModal = () => {
    setIsPlayerModalOpen(false);
    setSelectedPlayerId(null);
  };



 // Check if the current user is an admin
  const currentUserUid = auth.currentUser?.uid;
  const isAdmin = group?.admin?.includes(currentUserUid);


  if (loading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center h="100vh">
        <Heading color="red.500">Error: {error}</Heading>
      </Center>
    );
  }

  if (!group) {
    return (
      <Center h="100vh">
        <Heading color="gray.500">Group not found</Heading>
      </Center>
    );
  }



  return (
    <GroupProvider 
      value={{
        groupId,
        isAdmin,
        group,
        openPlayerModal,
        closePlayerModal,
        selectedPlayerId,
        isPlayerModalOpen,
      }}>

      <GroupHeader groupId={groupId} handleOpenEditModal={handleOpenEditModal} />

      <Outlet />

      <EditGroupDrawer
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        group={group}
        groupId={groupId}
        isEditMode
        isAdmin={isAdmin}
        onGroupUpdated={reloadGroup}
      />

      <PlayerProfileDrawer
        isOpen={isPlayerModalOpen}
        onClose={closePlayerModal}
        playerId={selectedPlayerId}
        groupId={groupId}
        isAdmin={isAdmin}
      />

    </GroupProvider>
  );
}

export default GroupLayout;
