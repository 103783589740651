import { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useUserContext } from "../context/UserContext";

/**
 * Hook to calculate and fetch user match statistics and group information.
 * @param {string} userId - The ID of the user.
 */
const useUserStats = (userId) => {
  
  const { setGroups } = useUserContext();
  const [matchStats, setMatchStats] = useState({
    matchesPlayed: 0,
    goalsScored: 0,
    wins: 0,
  });

  useEffect(() => {
    if (!userId) return;

    /**
     * Fetch groups and calculate match statistics.
     */
    const fetchUserStats = async () => {
      try {
        // Fetch groups
        const groupsQuery = query(
          collection(db, "groups"),
          where("members", "array-contains", userId)
        );
        const groupsSnapshot = await getDocs(groupsQuery);

        const userGroups = groupsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setGroups(userGroups); // Update global groups state
        calculateMatchStats(userId, userGroups); // Calculate statistics
      } catch (error) {
        console.error("Error fetching user stats:", error.message);
      }
    };

    fetchUserStats();
  }, [userId, setGroups]);

  /**
   * Calculate match statistics for the user.
   * @param {string} userId - The user ID.
   * @param {Array} userGroups - Groups the user is a part of.
   */
  const calculateMatchStats = async (userId, userGroups) => {
    try {
      let totalMatches = 0;
      let totalGoals = 0;
      let totalWins = 0;
  
      for (const group of userGroups) {
        const matchesQuery = query(
          collection(db, "groups", group.id, "matches")
        );
        const matchesSnapshot = await getDocs(matchesQuery);
  
        for (const matchDoc of matchesSnapshot.docs) {
          const matchData = matchDoc.data();
  
          // Include only matches with an endTime
          if (!matchData.endTime) {
            continue; // Skip matches without an endTime
          }
  
          const playedInTeam1 = matchData.players.team1.some(
            (player) => player.uid === userId
          );
          const playedInTeam2 = matchData.players.team2.some(
            (player) => player.uid === userId
          );
  
          if (playedInTeam1 || playedInTeam2) {
            totalMatches++;
  
            const team = playedInTeam1 ? "team1" : "team2";
            const playerStats = matchData.players[team].find(
              (player) => player.uid === userId
            );
  
            if (playerStats) {
              totalGoals += playerStats.goals || 0;
            }
  
            if (
              (playedInTeam1 && matchData.score.team1 > matchData.score.team2) ||
              (playedInTeam2 && matchData.score.team2 > matchData.score.team1)
            ) {
              totalWins++;
            }
          }
        }
      }
  
      setMatchStats({
        matchesPlayed: totalMatches,
        goalsScored: totalGoals,
        wins: totalWins,
      });
    } catch (error) {
      console.error("Error calculating match statistics:", error.message);
    }
  };
  

  return {
    matchStats,
  };
};

export default useUserStats;
