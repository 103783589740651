import React from "react";
import { Flex, Text, Spinner, Heading, Grid } from "@chakra-ui/react";
import usePublicMatches from "../../hooks/usePublicMatches";
import FindAMatchListing from "./FindAMatchListing";

function PublicMatches() { // Default grid value set to 3
  
  const { publicMatches, loading, error } = usePublicMatches();

  if (loading) {
    return (
      <Flex justify="center" align="center" height="100%">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex justify="center" align="center" height="100%">
        <Text color="red.500">{error}</Text>
      </Flex>
    );
  }



  return (
    <Flex direction="column" gap={4} w="100%">
      <Heading size="2xl">Find a Match</Heading>
      {publicMatches.length === 0 ? (
        <Text>No public matches found.</Text>
      ) : (
        <Grid templateColumns={{ base: "repeat(1, 1fr)", md: `repeat(1, 1fr)` }} gap={4}>
          {publicMatches.map((match) => (
            <FindAMatchListing key={match.id} match={match} playerId={null} />
          ))}
        </Grid>
      )}
    </Flex>
  );
}

export default PublicMatches;
