import { useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useToast } from "@chakra-ui/react";
import { db } from "../firebaseConfig";
import { useUserContext } from "../context/UserContext";

const useMatchActions = (match) => {
  const { currentUser } = useUserContext();
  const toast = useToast();

  const [isJoined, setIsJoined] = useState(false);

  // Update local state based on the current user's participation
  useEffect(() => {
    if (currentUser && match?.playerIds?.includes(currentUser.id)) {
      setIsJoined(true);
    } else {
      setIsJoined(false);
    }
  }, [currentUser, match]);

  const canCancel = () => {
    if (!match?.scheduledTime) return false;
    const now = new Date();
    const matchTime = new Date(match.scheduledTime);
    const twoHoursBeforeMatch = new Date(matchTime.getTime() - 2 * 60 * 60 * 1000);
    return now < twoHoursBeforeMatch;
  };
  const handleJoinMatch = async ({ groupId, matchId }) => {
  
    if (!matchId) {
      console.error("Match ID is undefined. Cannot proceed.");
      toast({
        title: "Invalid Match",
        description: "The match ID is undefined. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return null;
    }
  
    if (!currentUser) {
      console.error("User is not authenticated.");
      toast({
        title: "Authentication Required",
        description: "You must be logged in to join the match.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return null;
    }
  
    const playerUID = currentUser.id;
    const playerName = currentUser.displayName || "Anonymous";
  
    try {
      const matchRef = doc(db, "groups", groupId, "matches", matchId);
      const matchSnap = await getDoc(matchRef);
  
      if (!matchSnap.exists()) {
        console.error("Match not found in Firestore:", { groupId, matchId });
        toast({
          title: "Match Not Found",
          description: "The match you are trying to join does not exist.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return null;
      }
  
      const matchData = matchSnap.data();
  
      const team1Players = matchData.players?.team1 || [];
      const team2Players = matchData.players?.team2 || [];
      const playerCount = matchData.playerCount || { team1: 0, team2: 0 };
      const playerIds = matchData.playerIds || [];
  
      if (
        team1Players.length >= playerCount.team1 &&
        team2Players.length >= playerCount.team2
      ) {
        console.warn("Match is full. Cannot join.");
        toast({
          title: "Match Full",
          description: "The match is already full. You cannot join.",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
        return null;
      }
  
      let updatedTeam1 = [...team1Players];
      let updatedTeam2 = [...team2Players];
  
      if (team1Players.length < playerCount.team1) {
        updatedTeam1.push({ uid: playerUID, name: playerName });
      } else if (team2Players.length < playerCount.team2) {
        updatedTeam2.push({ uid: playerUID, name: playerName });
      }
  
      const updatedPlayerIds = Array.from(new Set([...playerIds, playerUID]));
  
      const updatedMatchData = {
        ...matchData,
        players: {
          team1: updatedTeam1,
          team2: updatedTeam2,
        },
        playerIds: updatedPlayerIds,
      };
  
      await updateDoc(matchRef, updatedMatchData);
  
      setIsJoined(true);
  
      toast({
        title: "Successfully Joined",
        description: "You have joined the match!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
  
      return { ...updatedMatchData, id: matchId }; // Ensure ID is retained
    } catch (error) {
      console.error("Error joining match:", error.message);
      toast({
        title: "Error",
        description: "Failed to join the match. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return null;
    }
  };
  

  const handleCancelParticipation = async () => {

    if (!canCancel()) {
      console.warn("Too late to cancel the match.");
      toast({
        title: "Too Late to Cancel",
        description: "You can only cancel participation up to 2 hours before the match.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return null;
    }

    try {
      const matchRef = doc(db, "groups", match.groupId, "matches", match.id);
      const matchSnap = await getDoc(matchRef);

      if (!matchSnap.exists()) {
        console.error("Match not found in Firestore:", { groupId: match.groupId, matchId: match.id });
        toast({
          title: "Match Not Found",
          description: "The match you are trying to leave does not exist.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return null;
      }

      const matchData = matchSnap.data();

      const updatedTeam1 = matchData.players?.team1.filter(
        (player) => player.uid !== currentUser.id
      ) || [];
      const updatedTeam2 = matchData.players?.team2.filter(
        (player) => player.uid !== currentUser.id
      ) || [];
      const updatedPlayerIds = matchData.playerIds?.filter(
        (id) => id !== currentUser.id
      ) || [];



      const updatedMatchData = {
        ...matchData,
        players: {
          team1: updatedTeam1,
          team2: updatedTeam2,
        },
        playerIds: updatedPlayerIds,
      };

      await updateDoc(matchRef, updatedMatchData);

      setIsJoined(false);

      toast({
        title: "Participation Cancelled",
        description: "You have successfully left the match.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      return updatedMatchData;
    } catch (error) {
      console.error("Error canceling participation:", error.message);
      toast({
        title: "Error",
        description: "Failed to cancel participation. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return null;
    }
  };
  

  return { isJoined, canCancel, handleJoinMatch, handleCancelParticipation };
};

export default useMatchActions;
