import {
  Flex,
  Grid,
  GridItem,
  Text,
  Heading,
  Spinner,
} from "@chakra-ui/react";
import { MapPin } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useUserContext} from "../../context/UserContext";
import useAllGroups from "../../hooks/useAllGroups";

function AllGroupsList() {
  const { currentUser } = useUserContext();
  const { loading, error, allGroups : groups } = useAllGroups();
  const navigate = useNavigate();

  if (loading) {
    return (
      <Flex justify="center" align="center" h="100%">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex justify="center" align="center" h="100%">
        <Text color="red.500">{error}</Text>
      </Flex>
    );
  }

  if (currentUser?.role !== "superAdmin") {
    return (
      <Flex justify="center" align="center" h="100%">
        <Text color="red.500">You do not have access to this page</Text>
      </Flex>
    );
  }

  return (
    <Grid templateColumns="repeat(auto-fill, minmax(250px, 1fr))" gap={4} px={4}>
      {groups.length === 0 ? (
        <Text color="white" textAlign="center" w="100%">
          No groups found in the database.
        </Text>
      ) : (
        groups.map((group) => (
          <GridItem
            key={group.id}
            bg="gray.800"
            p={4}

            _hover={{ bg: "gray.700", cursor: "pointer" }}
            onClick={() => navigate(`/groups/${group.id}`)}
          >
            <Flex direction="column" gap={4}>
              <Flex gap={4} align="center">
                <Flex align="center" gap={1}>
                  <MapPin size={16} color="#AAA" />
                  <Text fontSize="sm">{group.city || "N/A"}</Text>
                </Flex>
              </Flex>
              <Heading size="2xl" color="white" lineHeight={0.9}>
                {group.name}
              </Heading>
            </Flex>
          </GridItem>
        ))
      )}
    </Grid>
  );
}

export default AllGroupsList;
