import React from "react";
import {
  Flex,
  Box,
  Avatar,
  Text,
  Spinner,
  Heading,
  Grid,
  GridItem,
  Image,
  Tag,
  Badge,
} from "@chakra-ui/react";
import useGroupMembers from "../../hooks/useGroupMembers";
import placeholder from "../../img/playerProfilePlaceholder.jpg";
import { useGroupContext } from "../../context/GroupContext";

const GroupMembers = () => {
  const { groupId, openPlayerModal, group } = useGroupContext();
  const { groupMembers, loading, error } = useGroupMembers(groupId);

  console.log(group);
  const handleMemberClick = (uid) => {
    console.log("Clicked member UID:", uid); // Debugging log
    openPlayerModal(uid);
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" height="100%">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex justify="center" align="center" height="100%">
        <Text color="red.500">{error}</Text>
      </Flex>
    );
  }

  return (
    <Box>
      {groupMembers.length === 0 ? (
        <Text color="grey.500">No members found in this group.</Text>
      ) : (
        <Grid
          templateColumns={{ base: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr" }}
          gap={{ base: 4, md: 8 }}>
          {groupMembers.map((member) => (
            <GridItem
              position={"relative"}
              overflow={"hidden"}
              as={Flex}
              key={member.id}
              bg="grey.800"
              direction="column"
              cursor={"pointer"}
              transition={"all 0.2s ease-in-out"}
              _hover={{
                bg: "grey.900",
                transform: "scale(1.01)",
              }}
              onClick={() => handleMemberClick(member.uid)}>
              <Flex gap={4}>
                <Avatar
                  h={{ base: "82px", md: "88px" }}
                  w={{ base: "82px", md: "88px" }}
                  display="block"
                  aspectRatio="1/1"
                  borderRadius="0"
                  name={`${member.firstName || ""} ${member.lastName || ""}`}
                  src={member.profileImage || placeholder}
                />
                <Flex direction="column" gap={0} p={4}>
                  <Heading size="md" lineHeight={1}>
                    {member.firstName || "Unknown"}{" "}
                  </Heading>
                  <Flex gap={4} alignItems="center">
                    <Heading size="xl" lineHeight={1} color="lightGreen.300">
                      {member.lastName || ""}
                    </Heading>
                    {member.countryCode && (
                      <Image
                        src={`https://flagcdn.com/w80/${member.countryCode.toLowerCase()}.png`}
                        style={{ width: "24px", height: "24px" }}
                        alt={member.country}
                        title={member.country}
                      />
                    )}
                  </Flex>
                </Flex>
              </Flex>
              {group?.admin?.includes(member.uid) && (
                <Badge
                  colorScheme="black"
                  size="sm"
                  position={"absolute"}
                  right={0}
                  zIndex={2}
                  transformOrigin={"center"}
                  transform="rotate(45deg) translate(30px, -5px)"
                  width="100px"
                  textAlign="center">
                  Captain
                </Badge>
              )}
            </GridItem>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default GroupMembers;
