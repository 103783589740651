import { useGroupContext } from "../../context/GroupContext";
import Calendar from "../Calendar";

function GroupCalendar() {

  const { groupId } = useGroupContext();


  return (
    <Calendar groupId={groupId} grid={3} />
  );
}

export default GroupCalendar;
