import React, { createContext, useState, useContext } from "react";

const AppContext = createContext();
export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const [members, setMembers] = useState([]);
  
  const formatScheduledTime = (timeString) => {
    if (!timeString) return "N/A";
    const date = new Date(timeString);
    if (isNaN(date)) return timeString; // Return the original string if invalid date
    return date
      .toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
      .replace(":", "H");
  };

  return (
    <AppContext.Provider value={{ members, setMembers, formatScheduledTime }}>
      {children}
    </AppContext.Provider>
  );
};
