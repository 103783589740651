import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Input,
  useToast,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { doc, getDocs, query, where, collection, setDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";

function JoinGroupModal({ isOpen, onClose, userId, onGroupJoined }) {
  const [groupCode, setGroupCode] = useState("");
  const [isJoining, setIsJoining] = useState(false);
  const toast = useToast();
  const inputRef = useRef(null); // Référence pour l'entrée

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        inputRef.current?.focus(); // Applique le focus après un léger délai
      }, 100); // Délai pour garantir que la modal est rendue
    }
  }, [isOpen]);

  const handleJoinGroup = async () => {
    if (!groupCode) {
      toast({
        title: "Group code required",
        description: "Please enter a valid group code.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsJoining(true);
    try {
      const q = query(collection(db, "groups"), where("code", "==", groupCode));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const groupDoc = querySnapshot.docs[0];
        const groupData = groupDoc.data();

        if (!groupData.members.includes(userId)) {
          const groupRef = doc(db, "groups", groupDoc.id);
          await setDoc(
            groupRef,
            { members: [...groupData.members, userId] },
            { merge: true }
          );
          toast({
            title: "Successfully joined the group!",
            description: `You are now a member of: ${groupData.name}`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });

          if (onGroupJoined) {
            onGroupJoined({ id: groupDoc.id, ...groupData });
          }
          onClose();
        } else {
          toast({
            title: "Already a member",
            description: "You are already a member of this group.",
            status: "info",
            duration: 3000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: "Group not found",
          description: "Please check the group code and try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error joining group:", error.message);
      toast({
        title: "Error joining group",
        description: "Failed to join the group. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsJoining(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader><Heading>Join Group</Heading></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap={4}>
            <Input
              ref={inputRef} // Ajout de la référence pour focus
              placeholder="Enter group code"
              value={groupCode}
              onChange={(e) => setGroupCode(e.target.value)}
            />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Cancel
          </Button>
          <Button
            bg="lightGreen.300"
            onClick={handleJoinGroup}
            isLoading={isJoining}
            isDisabled={!groupCode}
          >
            Join
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default JoinGroupModal;
