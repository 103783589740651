import {
  Flex,
  Heading,
  Button,
  TabList,
  Tab,
  Box,
  useBreakpointValue,
} from "@chakra-ui/react";
import matchBg from "../../img/matchBg.jpg";
import { useAppContext } from "../../context/AppContext";
import { useUserContext } from "../../context/UserContext";

function MatchHeader({
  matchData,
  score,
  timer,
  isRunning,
  isMatchEnded,
  setIsDialogOpen,
  handleEditTeams,
  isAuthorized,
}) {
  const { currentUser } = useUserContext();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { formatScheduledTime } = useAppContext();
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <Flex
      direction={"column"}
      w={"100%"}
      backgroundImage={matchBg}
      backgroundPosition={"center"}
      backgroundSize={"cover"}
      align={{ base: "center", md: "flex-start" }}>
      <Flex
        py={16}
        px={{ base: 8, md: 24 }}
        justify="center"
        align={"center"}
        w={"100%"}
        direction={"row"}
        gap={{ base: 8, md: 16 }}
        position={"relative"}>
        {!isMobile && (
          <Flex zIndex={2} flex={1} justifyContent={"flex-end"}>
            <Heading
              fontSize={{ base: "xl", md: "2xl" }}
              textTransform={"capitalize"}
              bg={`${matchData?.teamColors?.team1}.400` || "lightGreen.300"}
              color={
                matchData?.teamColors?.team1 === "yellow" ? "grey.950" : "white"
              }
              fontWeight={"bold"}>
              {matchData?.teamColors?.team1 || "Team 1"}
            </Heading>
          </Flex>
        )}

        {/* Score */}
        <Flex direction="column" gap={2} align="center" zIndex={2}>
          <Flex gap={{ base: 8, md: 16 }} align={"center"}>
            <Flex direction={"column"} align="center" gap={2}>
              <Heading fontSize="82px" color={"white"}>
                {score.team1}
              </Heading>
              {isMobile && (
                <Flex zIndex={2} flex={1} justifyContent={"flex-end"}>
                  <Heading
                    fontSize={"md"}
                    textTransform={"capitalize"}
                    bg={
                      matchData?.teamColors?.team1 === "black"
                        ? "grey.950"
                        : `${matchData?.teamColors?.team1}.400` || "lightGreen.300"
                    }
                    color={
                      matchData?.teamColors?.team1 === "yellow"
                        ? "grey.950"
                        : "white"
                    }
                    fontWeight={"bold"}>
                    {matchData?.teamColors?.team1 || "Team 1"}
                  </Heading>
                </Flex>
              )}
            </Flex>

            <Flex direction={"column"}>
              <Heading
                px={2}
                py={1}
                fontSize={{ base: "2xl", md: "3xl" }}
                bg={"grey.800"}
                color={"white"}
                minW={{ base: "65px", md: "85px" }}
                textAlign={"center"}>
                {!isMatchEnded && isRunning && (
                  <Box
                    onClick={isAuthorized ? () => setIsDialogOpen(true) : null} // Désactiver si non autorisé
                    cursor={isAuthorized ? "pointer" : "not-allowed"} // Changer le style du curseur
                    color={isAuthorized ? "white" : "gray.500"} // Modifier la couleur si non autorisé
                  >
                    {formatTime(timer)}
                  </Box>
                )}

                {!isMatchEnded && !isRunning && (
                  <>{formatScheduledTime(matchData.scheduledTime)}</>
                )}

                {isMatchEnded && <>End</>}
              </Heading>
            </Flex>

            {!matchData?.startTime && !isMatchEnded && isAuthorized === true && currentUser.id === matchData.createdBy && (
              <Button
                size={{ base: "md", md: "lg" }}
                bg="pink.400"
                color="white"
                onClick={handleEditTeams}
                position={"absolute"}
                top={"0px"}
                left={"50%"}
                transform={"translateX(-50%)"}>
                Edit lineups
              </Button>
            )}

<Flex direction={"column"} align="center" gap={2}>
              <Heading fontSize="82px" color={"white"}>
                {score.team2}
              </Heading>
              {isMobile && (
                <Flex zIndex={2} flex={1} justifyContent={"flex-end"}>
                  <Heading
                    fontSize={"md"}
                    textTransform={"capitalize"}
                   bg={
                matchData?.teamColors?.team2 === "black"
                  ? "grey.950"
                  : `${matchData?.teamColors?.team2}.400` || "lightGreen.300"
              }
                    color={
                      matchData?.teamColors?.team2 === "yellow"
                        ? "grey.950"
                        : "white"
                    }
                    fontWeight={"bold"}>
                    {matchData?.teamColors?.team2 || "Team 2"}
                  </Heading>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>

        {!isMobile && (
          <Flex zIndex={2} flex={1}>
            <Heading
              fontSize={{ base: "xl", md: "2xl" }}
              textTransform={"capitalize"}
              bg={
                matchData?.teamColors?.team2 === "black"
                  ? "grey.950"
                  : `${matchData?.teamColors?.team2}.400` || "lightGreen.300"
              }
              color={
                matchData?.teamColors?.team2 === "yellow" ? "grey.950" : "white"
              }
              fontWeight={"bold"}>
              {matchData?.teamColors?.team2 || "Team 2"}
            </Heading>
          </Flex>
        )}
      </Flex>

      <TabList
        zIndex={2}
        px={{ base: 0, md: 8 }}
        gap={2}
        w={"fit-content"}
        justifyContent={{ base: "center", md: "flex-start" }}>
        <Tab
          py={{ base: 3, md: 4 }}
          px={{ base: 4, md: 8 }}
          borderRadius={"0"}
          _selected={{
            bg: "grey.950",
            border: "none",
          }}>
          <Heading fontSize={"xl"}>Lineups</Heading>
        </Tab>
        <Tab
          py={{ base: 3, md: 4 }}
          px={{ base: 4, md: 8 }}
          borderRadius={"0"}
          _selected={{
            bg: "grey.950",
            border: "none",
          }}
          isDisabled={!isRunning && !isMatchEnded}>
          <Heading fontSize={"xl"}>Summary</Heading>
        </Tab>
      </TabList>
    </Flex>
  );
}

export default MatchHeader;
