import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from "../firebaseConfig";

const useMatchLogic = (currentUser) => {
  const { groupId, matchId } = useParams();
  const navigate = useNavigate();
  const [matchData, setMatchData] = useState(null);
  const [score, setScore] = useState({ team1: 0, team2: 0 });
  const [goals, setGoals] = useState({});
  const [timer, setTimer] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isMatchEnded, setIsMatchEnded] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const cancelRef = useRef();
  const [pendingGoals, setPendingGoals] = useState({});
  const [pendingOwnGoals, setPendingOwnGoals] = useState({});
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [cooldowns, setCooldowns] = useState({});

  const initializePlayers = (players) =>
    players.map((player) => ({
      ...player,
      goals: player.goals || 0,
      minutes: player.minutes || [],
    }));

    useEffect(() => {
      const matchRef = doc(db, "groups", groupId, "matches", matchId);
    
      const unsubscribe = onSnapshot(matchRef, (matchSnap) => {
        if (matchSnap.exists()) {
          const data = matchSnap.data();
    
          setMatchData({
            ...data,
            players: {
              team1: initializePlayers(data.players.team1),
              team2: initializePlayers(data.players.team2),
            },
          });
    
          setScore(data.score || { team1: 0, team2: 0 });
          setGoals(data.goals || {});
          setIsMatchEnded(!!data.endTime);
    
          if (data.startTime && !data.endTime) {
            const elapsedTime = Math.floor(
              (Date.now() - new Date(data.startTime).getTime()) / 1000
            );
            setTimer(elapsedTime);
            setIsRunning(true);
          }
    
          // Determine if the user is authorized
          if (currentUser) {
            const allPlayers = [...data.players.team1, ...data.players.team2];
            const isUserAuthorized = allPlayers.some(
              (player) => player.uid === currentUser.uid
            );
    
            setIsAuthorized(isUserAuthorized); // Only allow authorized users
          } else {
            setIsAuthorized(false);
          }
        } else {
          navigate("/not-found"); // Redirect if match doesn't exist
        }
      });
    
      // Cleanup listener on component unmount
      return () => {
        unsubscribe();
      };
    }, [groupId, matchId, currentUser, navigate]);
    

  useEffect(() => {
    let intervalId;

    if (isRunning) {
      intervalId = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);

      return () => {
        clearInterval(intervalId); // Nettoyer l'intervalle correctement
      };
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isRunning]);

  const handleGoal = async (team, player, change = 1) => {
    if (!player || typeof player !== "object" || !player.name) {
      console.error("Invalid player data:", player);
      return;
    }

    const currentMinute = Math.floor(timer / 60) + 1;

    // Clone des joueurs pour éviter la mutabilité
    const updatedPlayers = JSON.parse(JSON.stringify(matchData.players));
    const teamPlayers = updatedPlayers[team];

    // Rechercher le joueur avec une fonction robuste
    const playerIndex = teamPlayers.findIndex(
      (p) =>
        (player.uid && p.uid === player.uid) || // Priorité à l'UID si présent
        (!player.uid && p.name === player.name) // Fallback sur le nom si UID est null
    );

    if (playerIndex === -1) {
      console.error(`Player not found in ${team}:`, player);
      return;
    }

    // Mise à jour des données du joueur
    const existingPlayer = teamPlayers[playerIndex];
    if (change > 0) {
      teamPlayers[playerIndex] = {
        ...existingPlayer,
        goals: (existingPlayer.goals || 0) + change,
        minutes: [...(existingPlayer.minutes || []), currentMinute],
      };
    } else {
      // Pour annuler un but, on retire un goal et on retire le dernier minute ajouté
      const updatedMinutes = [...(existingPlayer.minutes || [])];
      updatedMinutes.pop(); // Retire la dernière minute ajoutée
      teamPlayers[playerIndex] = {
        ...existingPlayer,
        goals: (existingPlayer.goals || 0) + change,
        minutes: updatedMinutes,
      };
    }

    // Mise à jour du score
    const updatedScore = {
      ...score,
      [team]: score[team] + change,
    };

    // Mise à jour des données du match
    const updatedMatchData = {
      ...matchData,
      players: updatedPlayers,
      score: updatedScore,
    };

    setMatchData(updatedMatchData);
    setScore(updatedScore);

    try {
      const matchRef = doc(db, "groups", groupId, "matches", matchId);
      await updateDoc(matchRef, updatedMatchData);
    } catch (error) {
      console.error("Error updating match:", error.message);
    }
  };

  const handleOwnGoal = async (team, player, change = 1) => {
    console.log("Handling own goal for team:", team, "player:", player, "change:", change);
  
    // Déterminer l'équipe adverse
    const opponentTeam = team === "team1" ? "team2" : "team1";
  
    // Ajouter ou retirer un but à l'équipe adverse
    const currentMinute = Math.floor(timer / 60) + 1;
  
    // Clone des joueurs pour éviter la mutabilité
    const updatedPlayers = JSON.parse(JSON.stringify(matchData.players));
    const teamPlayers = updatedPlayers[team];
  
    // Recherche du joueur dans son équipe d'origine
    const playerIndex = teamPlayers.findIndex(
      (p) =>
        (player.uid && p.uid === player.uid) || // Correspondance basée sur UID si disponible
        (!player.uid && p.name.trim().toLowerCase() === player.name.trim().toLowerCase()) // Fallback sur name
    );
  
    if (playerIndex === -1) {
      console.error(`Player not found in ${team}:`, player);
      return;
    }
  
    const existingPlayer = teamPlayers[playerIndex];
  
    if (change > 0) {
      // Ajout d'un own goal
      teamPlayers[playerIndex] = {
        ...existingPlayer,
        ownGoals: [...(existingPlayer.ownGoals || []), currentMinute],
      };
    } else {
      // Retirer un own goal
      const updatedOwnGoals = [...(existingPlayer.ownGoals || [])];
      updatedOwnGoals.pop(); // Retirer le dernier own goal ajouté
      teamPlayers[playerIndex] = {
        ...existingPlayer,
        ownGoals: updatedOwnGoals,
      };
    }
  
    // Mise à jour du score de l'équipe adverse
    const updatedScore = {
      ...score,
      [opponentTeam]: score[opponentTeam] + change,
    };
  
    // Mise à jour des données du match
    const updatedMatchData = {
      ...matchData,
      players: updatedPlayers,
      score: updatedScore,
    };
  
    setMatchData(updatedMatchData);
    setScore(updatedScore);
  
    try {
      const matchRef = doc(db, "groups", groupId, "matches", matchId);
      await updateDoc(matchRef, updatedMatchData);
      console.log("Firestore updated successfully after own goal handling");
    } catch (error) {
      console.error("Error updating match:", error.message);
    }
  };
  

  const addOwnGoal = (team, player) => {
    const key = `${team}-${player.uid || player.name}`;

    // Ajouter immédiatement un own goal (affichage direct)
    handleOwnGoal(team, player);

    // Configurer un own goal en attente
    setPendingOwnGoals((prev) => ({
      ...prev,
      [key]: {
        team,
        player,
        timeoutId: setTimeout(() => {
          // Supprimer l'own goal en attente après 10 secondes (confirmation automatique)
          setPendingOwnGoals((pending) => {
            const updated = { ...pending };
            delete updated[key];
            return updated;
          });
        }, 10000), // 10 secondes
        counter: 10,
      },
    }));

    startCooldown(team, player.uid || player.name);
  };
  

  const handleRemoveGoal = async (team, playerName, minute) => {
    // Clone des joueurs pour éviter la mutabilité
    const updatedPlayers = JSON.parse(JSON.stringify(matchData.players));
    const teamPlayers = updatedPlayers[team];

    // Rechercher le joueur avec une fonction robuste
    const playerIndex = teamPlayers.findIndex(
      (p) => p.name === playerName
    );

    if (playerIndex === -1) {
      console.error(`Player not found in ${team}:`, playerName);
      return;
    }

    // Mise à jour des données du joueur
    const existingPlayer = teamPlayers[playerIndex];
    const updatedMinutes = existingPlayer.minutes.filter((m) => m !== minute);

    if (updatedMinutes.length === existingPlayer.minutes.length) {
      console.error(`Minute not found for player ${playerName} in team ${team}`);
      return;
    }

    teamPlayers[playerIndex] = {
      ...existingPlayer,
      goals: existingPlayer.goals - 1,
      minutes: updatedMinutes,
    };

    // Mise à jour du score
    const updatedScore = {
      ...score,
      [team]: score[team] - 1,
    };

    // Mise à jour des données du match
    const updatedMatchData = {
      ...matchData,
      players: updatedPlayers,
      score: updatedScore,
    };

    setMatchData(updatedMatchData);
    setScore(updatedScore);

    try {
      const matchRef = doc(db, "groups", groupId, "matches", matchId);
      await updateDoc(matchRef, updatedMatchData);
    } catch (error) {
      console.error("Error updating match:", error.message);
    }
  };

  const handleRemoveOwnGoal = async (team, playerName, minute) => {
    // Déterminer l'équipe originale (celle qui a concédé le own goal)
    const originalTeam = team === "team1" ? "team2" : "team1";
  
    // Clone des joueurs pour éviter la mutabilité
    const updatedPlayers = JSON.parse(JSON.stringify(matchData.players));
    const teamPlayers = updatedPlayers[originalTeam];
  
    // Trouver l'index du joueur
    const playerIndex = teamPlayers.findIndex(
      (p) => p.name.trim().toLowerCase() === playerName.trim().toLowerCase()
    );
  
    if (playerIndex === -1) {
      console.error(`Player not found in ${originalTeam}:`, playerName);
      return;
    }
  
    // Retirer la minute spécifique du own goal
    const updatedOwnGoals = teamPlayers[playerIndex].ownGoals.filter((m) => m !== minute);
  
    if (updatedOwnGoals.length === teamPlayers[playerIndex].ownGoals.length) {
      console.error(`Minute not found for player ${playerName} in team ${originalTeam}`);
      return;
    }
  
    teamPlayers[playerIndex] = {
      ...teamPlayers[playerIndex],
      ownGoals: updatedOwnGoals,
    };
  
    // Mise à jour du score : on réduit le score de l'équipe adverse (qui a bénéficié du own goal)
    const updatedScore = {
      ...score,
      [team]: score[team] - 1,
    };
  
    // Mise à jour des données du match
    const updatedMatchData = {
      ...matchData,
      players: updatedPlayers,
      score: updatedScore,
    };
  
    // Mise à jour de l'état local
    setMatchData(updatedMatchData);
    setScore(updatedScore);
  
    try {
      // Mise à jour dans Firestore
      const matchRef = doc(db, "groups", groupId, "matches", matchId);
      await updateDoc(matchRef, updatedMatchData);
    } catch (error) {
      console.error("Error updating match after removing own goal:", error.message);
    }
  };
  

  const handleStartMatch = async () => {
    if (!isRunning) {
      const startTime = new Date().toISOString();
      setIsRunning(true);

      try {
        const matchRef = doc(db, "groups", groupId, "matches", matchId);
        await updateDoc(matchRef, { startTime });
        // Update the matchData state to reflect the startTime immediately
        setMatchData((prevData) => ({
          ...prevData,
          startTime,
        }));
      } catch (error) {
        console.error("Error saving match start time:", error.message);
      }
    }
  };

  const handleEndMatch = async () => {
    setIsDialogOpen(false);
    if (isRunning) {
      setIsRunning(false);
      const endTime = new Date().toISOString();

      try {
        const matchRef = doc(db, "groups", groupId, "matches", matchId);
        await updateDoc(matchRef, { endTime });
        setIsMatchEnded(true);
        setMatchData((prevData) => ({
          ...prevData,
          endTime,
        }));
      } catch (error) {
        console.error("Error saving match end time:", error.message);
      }
    }
  };

  const handleResetMatch = async () => {
    try {
      const matchRef = doc(db, "groups", groupId, "matches", matchId);

      // Réinitialiser les données du match
      const resetData = {
        startTime: null,
        score: { team1: 0, team2: 0 },
        players: {
          team1: matchData.players.team1.map((player) => ({
            ...player,
            goals: 0,
            minutes: [],
            ownGoals: [],
          })),
          team2: matchData.players.team2.map((player) => ({
            ...player,
            goals: 0,
            minutes: [],
            ownGoals: [],
          })),
        },
      };

      // Mise à jour Firestore
      await updateDoc(matchRef, resetData);

      // Mise à jour locale
      setMatchData((prev) => ({
        ...prev,
        ...resetData,
      }));
      setScore({ team1: 0, team2: 0 });
      setTimer(0);
      setIsRunning(false);
      setIsMatchEnded(false);

      setIsDialogOpen(false); // Fermer la boîte de dialogue
    } catch (error) {
      console.error("Error resetting match:", error.message);
    }
  };

  const addGoal = (team, player) => {
    const key = `${team}-${player.uid || player.name}`;

    // Ajouter immédiatement un but (affichage direct)
    handleGoal(team, player, 1);

    // Configurer un but en attente
    setPendingGoals((prev) => ({
      ...prev,
      [key]: {
        team,
        player,
        timeoutId: setTimeout(() => {
          // Supprimer le but en attente après 10 secondes (confirmation automatique)
          setPendingGoals((pending) => {
            const updated = { ...pending };
            delete updated[key];
            return updated;
          });
        }, 10000), // 10 secondes
        counter: 10,
      },
    }));

    startCooldown(team, player.uid || player.name);
  };

  const startCooldown = (team, playerId) => {
    const key = `${team}-${playerId}`;
    setCooldowns((prev) => ({
      ...prev,
      [key]: true,
    }));

    setTimeout(() => {
      setCooldowns((prev) => {
        const updated = { ...prev };
        delete updated[key];
        return updated;
      });
    }, 10000); // Cooldown de 10 secondes
  };


  
  const cancelPendingGoal = (key) => {
    const pendingGoal = pendingGoals[key];
    if (!pendingGoal) return;

    const { team, player, timeoutId } = pendingGoal;

    // Supprimer le timeout d'annulation automatique
    clearTimeout(timeoutId);

    // Retirer le but (score et stats joueur)
    handleGoal(team, player, -1); // Utilisez -1 pour annuler le but

    // Supprimer le but en attente
    setPendingGoals((prev) => {
      const updated = { ...prev };
      delete updated[key];
      return updated;
    });
  };

  const cancelPendingOwnGoal = (key) => {
    const pendingOwnGoal = pendingOwnGoals[key];
    if (!pendingOwnGoal) return; // Si l'own goal a déjà été annulé, ne rien faire
  
    const { team, player, timeoutId } = pendingOwnGoal;
  
    // Supprimer le timeout d'annulation automatique
    clearTimeout(timeoutId);
  
    // Utiliser handleOwnGoal pour retirer un own goal avec une valeur négative
    handleOwnGoal(team, player, -1);
  
    // Supprimer l'own goal en attente
    setPendingOwnGoals((prev) => {
      const updated = { ...prev };
      delete updated[key];
      return updated;
    });
  
    console.log(`Own goal cancelled successfully for key: ${key}`);
  };
  
  
  

  return {
    matchData,
    score,
    timer,
    isRunning,
    isMatchEnded,
    isDialogOpen,
    setIsDialogOpen,
    isAuthorized,
    goals,
    pendingGoals,
    setPendingGoals,
    pendingOwnGoals,
    setPendingOwnGoals,
    addGoal,
    cooldowns,
    handleStartMatch,
    handleEndMatch,
    handleResetMatch,
    handleGoal,
    addOwnGoal,
    cancelPendingGoal,
    cancelPendingOwnGoal,
    handleRemoveGoal,
    handleRemoveOwnGoal,
    cancelRef,
  };
};

export default useMatchLogic;
