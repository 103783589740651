import React, { useState } from "react";
import { Flex, Text, chakra, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Button, IconButton, Badge, useBreakpointValue } from "@chakra-ui/react";
import useMatchLogic from "../../hooks/useMatchLogic";
import { Minus } from "lucide-react";
import OwnGoal from "../icons/OwnGoal";
import Goal from "../icons/Goal";

function Summary({ players, endTime }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const { handleRemoveGoal, handleRemoveOwnGoal } = useMatchLogic();
  const cancelRef = React.useRef();

  // Use Chakra's breakpoint values
  const isBase = useBreakpointValue({ base: true, md: false });

  // Helper to extract and sort goals
  const extractAndSortGoals = (teamPlayers, teamName) => {
    return teamPlayers.flatMap((player) =>
      (player.minutes || []).map((minute) => ({
        name: player.name,
        team: teamName,
        minute,
        type: "goal"
      }))
    );
  };

  // Helper to extract and sort own goals
  const extractAndSortOwnGoals = (teamPlayers, opponentTeamName) => {
    return teamPlayers.flatMap((player) =>
      (player.ownGoals || []).map((minute) => ({
        name: player.name,
        team: opponentTeamName, // Own goal goes to opponent team's side
        minute,
        type: "ownGoal"
      }))
    );
  };

  // Helper to format player name based on screen size
  const formatPlayerName = (name) => {
    if (isBase) {
      const [firstName, ...lastName] = name.split(" ");
      if (lastName.length > 0) {
        return `${firstName.charAt(0)}. ${lastName.join(" ")}`;
      }
      return firstName; // If no last name, return the first name in full
    }
    return name;
  };

  // Combine and sort all goals by minute
  const sortedGoals = [
    ...extractAndSortGoals(players.team1, "team1"),
    ...extractAndSortGoals(players.team2, "team2"),
    ...extractAndSortOwnGoals(players.team1, "team2"),
    ...extractAndSortOwnGoals(players.team2, "team1"),
  ].sort((a, b) => a.minute - b.minute); // Ensure chronological order

  let team1Score = 0;
  let team2Score = 0;

  // Annotate each goal with the cumulative score
  const goalsWithScore = sortedGoals.map((goal) => {
    if (goal.team === "team1") {
      team1Score++;
    } else if (goal.team === "team2") {
      team2Score++;
    }

    return {
      ...goal,
      score: `${team1Score}-${team2Score}`,
    };
  });

  const openDeleteConfirmation = (goal) => {
    setSelectedGoal(goal);
    setIsDialogOpen(true);
  };

  const handleDeleteGoal = () => {
    if (selectedGoal) {
      if (selectedGoal.type === "ownGoal") {
        handleRemoveOwnGoal(selectedGoal.team, selectedGoal.name, selectedGoal.minute);
      } else {
        handleRemoveGoal(selectedGoal.team, selectedGoal.name, selectedGoal.minute);
      }
    }
    setIsDialogOpen(false);
    setSelectedGoal(null);
  };

  return (
    <Flex direction="column" w="100%">
      {goalsWithScore.map((goal, index) => (
        <Flex
          key={index}
          align="center"
          w="100%"
          justify="space-between"
          borderBottom="3px solid"
          borderColor="grey.950"
          bg="grey.800"
          py={4}
          gap={{ base: 2, md: 4 }}
        >
          {/* Team 1 goal */}
          <Flex flex="1" justify={endTime ? "flex-end" : "space-between"} align={"center"} gap={4} px={2}>
            {goal.team === "team1" && (
              <>
                {!endTime && (
                  <IconButton
                    aria-label="Delete Goal"
                    icon={<Minus size={12} />}
                    size={"xs"}
                    bg={"pink.400"}
                    onClick={() => openDeleteConfirmation(goal)}
                  />
                )}
                <Flex gap={2} align={"center"}>
                  <Text fontWeight="400" color="grey.400" fontSize={{ base: "xs", md: "md" }}>
                    <chakra.span fontWeight="500" color="white">
                      {formatPlayerName(goal.name)}
                    </chakra.span>{" "}
                    ({goal.score})
                  </Text>
                  {goal.type === "ownGoal" ? <OwnGoal /> : <Goal />}
                </Flex>
              </>
            )}
          </Flex>

          {/* Goal minute */}
          <Badge>{goal.minute}'</Badge>

          {/* Team 2 goal */}
          <Flex flex="1" justify={"space-between"} align={"center"} gap={4} px={2}>
            {goal.team === "team2" && (
              <>
                <Flex gap={2} align={"center"}>
                  {goal.type === "ownGoal" ? <OwnGoal /> : <Goal />}
                  <Text fontWeight="400" color="grey.400" fontSize={{ base: "xs", md: "md" }}>
                    <chakra.span fontWeight="500" color="white">
                      {formatPlayerName(goal.name)}
                    </chakra.span>{" "}
                    ({goal.score})
                  </Text>
                </Flex>
                {!endTime && (
                  <IconButton
                    aria-label="Delete Goal"
                    icon={<Minus size={12} />}
                    size={"xs"}
                    bg={"pink.400"}
                    onClick={() => openDeleteConfirmation(goal)}
                  />
                )}
              </>
            )}
          </Flex>
        </Flex>
      ))}

      {/* Confirmation Dialog for Deleting a Goal */}
      <AlertDialog
        isOpen={isDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDialogOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Goal
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this goal? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDialogOpen(false)} variant={"ghost"}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteGoal} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}

export default Summary;
