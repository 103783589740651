import { useNavigate, useOutletContext } from "react-router-dom";
import {
  Flex,
  Text,
  Heading,
  SimpleGrid,
  GridItem,

  Button,
  HStack,
} from "@chakra-ui/react";
import { MapPin, Merge, Plus } from "lucide-react";
import useTheme from "../../hooks/useTheme";
import { useUserContext } from "../../context/UserContext";

function UserGroups () {
  const { groups } = useUserContext();
  const { theme } = useTheme();
  const {
    setIsGroupDrawerOpen,
    setIsJoinGroupModalOpen,
  } = useOutletContext();


  const navigate = useNavigate();


  
  return (
<>
            
            <HStack spacing={2}>
              <Button
              bg={"lightGreen.300"}
              size="lg"
              aria-label="Create a group"

              leftIcon={<Plus size={20} color={theme.colors.grey[950]} />}
              onClick={() => setIsGroupDrawerOpen(true)}>
              Create group
            </Button>
            <Button
              variant={"outline"}
              size="lg"
              aria-label="Create a group"

              leftIcon={<Merge size={20}  />}
              onClick={() => setIsJoinGroupModalOpen(true)}>
              Join group
            </Button>
            </HStack>

           
            {groups.length === 0 ? (
              <Text color={"white"} textAlign={"center"} w={"100%"}>
                No groups yet. Join or create a new group!
              </Text>
            ) : (

          <SimpleGrid columns={[1, 2, 3, 4]} direction="row" gap={4} w={"100%"}>
        
              {groups.map((group) => (
                <GridItem
                  key={group.id}
                  bg="gray.800"
                  p={4}
                  _hover={{ bg: "gray.700", cursor: "pointer" }}
                  onClick={() => navigate(`/groups/${group.id}`)}>
                  <Flex direction="column" gap={4}>
                    <Flex gap={4} align="center">
                      <Flex align="center" gap={1}>
                        <MapPin size={16} color="#AAA" />
                        <Text fontSize="sm">{group.city || "N/A"}</Text>
                      </Flex>
                    </Flex>
                    <Heading size="2xl" color="white" lineHeight={0.9}>
                      {group.name}
                    </Heading>
                  </Flex>
                </GridItem>
              ))}
           
          </SimpleGrid> 
        
        )}
       

     
    </>
  );
}

export default UserGroups;
