import { useState, useEffect } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebaseConfig";
import useAllGroups from "./useAllGroups";

/**
 * Custom hook to fetch all public matches that are not complete across groups.
 */
const usePublicMatches = () => {
  const { allGroups, loading: groupsLoading, error: groupsError } = useAllGroups();
  const [publicMatches, setPublicMatches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPublicMatches = async () => {
      try {
        setLoading(true);
        const matches = [];
        const now = new Date();

        for (const group of allGroups) {
          const matchesRef = collection(db, "groups", group.id, "matches");
          const publicMatchesQuery = query(matchesRef, where("public", "==", true));
          const matchesSnapshot = await getDocs(publicMatchesQuery);

          matchesSnapshot.forEach((doc) => {
            const matchData = doc.data();
            const matchTime = new Date(matchData.scheduledTime);

            // Calculate total players in both teams
            const totalPlayers = (matchData.players.team1?.length || 0) + (matchData.players.team2?.length || 0);
            const maxPlayers = (matchData.playerCount.team1 || 0) + (matchData.playerCount.team2 || 0);

            // Exclude matches that are full or scheduled in the past
            if (matchTime >= now && totalPlayers < maxPlayers) {
              matches.push({
                id: doc.id,
                groupId: group.id,
                ...matchData,
              });
            }
          });
        }

        setPublicMatches(matches);
      } catch (err) {
        console.error("Error fetching public matches:", err);
        setError("Failed to fetch public matches.");
      } finally {
        setLoading(false);
      }
    };

    if (!groupsLoading && allGroups.length > 0) {
      fetchPublicMatches();
    }
  }, [allGroups, groupsLoading]);

  





  return { 
    publicMatches, 
    loading: loading || groupsLoading, 
    error: error || groupsError,
  
  };
};

export default usePublicMatches;
