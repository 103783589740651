import React, { useState, useEffect } from "react";
import { ChakraProvider, Flex, Spinner } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebaseConfig"; // Firebase Auth
import Auth from "./components/Auth"; // Page de connexion/inscription
import GroupMembers from "./components/Group/GroupMembers"; // Page détails d'un groupe
import CreateMatchForm from "./components/CreateMatchForm"; // Page création d'un match
import Match from "./components/Match/Match"; // Page match en direct
import useTheme from "./hooks/useTheme";
import BackendLayout from "./BackendLayout"; // Layout pour Header et contenu des pages
import { AppProvider } from "./context/AppContext";
import { UserProvider } from "./context/UserContext";
import GroupStatistics from "./components/Group/GroupStatistics";
import GroupLayout from "./components/Group/GroupLayout";
import GroupCalendar from "./components/Group/GroupCalendar";
import UserCalendar from "./components/User/UserCalendar";
import UserLayout from "./components/User/UserLayout";
import UserGroups from "./components/User/UserGroups";
import AllGroupsList from "./components/Group/AllGroupsList";
import PublicMatches from "./components/Find/PublicMatches";

function App() {
  const [user, setUser] = useState(null); // Utilisateur connecté
  const [loading, setLoading] = useState(true); // Pour gérer l'état de chargement
  const { theme } = useTheme();

  // Vérifie si un utilisateur est connecté
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false); // Arrête le chargement après vérification
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <ChakraProvider theme={theme}>
        <Flex justify="center" align="center" height="100vh">
          <Spinner size="xl" />
        </Flex>
      </ChakraProvider>
    );
  }

  return (
    <ChakraProvider theme={theme}>
          <AppProvider>
      <UserProvider>
      <Router>
        <Routes>
          {/* Route d'accueil */}
          <Route
            path="/"
            element={
              user ? <Navigate to={`/profile`} replace /> : <Auth onAuthSuccess={() => setUser(auth.currentUser)} />
            }
          />

          <Route path="/" element={user ? <BackendLayout /> : <Navigate to="/" replace />}>
            
          <Route path="profile" element={<UserLayout userId={user?.uid} />}>
            <Route index element={<UserGroups userId={user?.uid} />} />
            <Route path="calendar" element={<UserCalendar />} />
          </Route>
            
            {/* Groups Layout */}
    <Route path="groups/:groupId" element={<GroupLayout />}>
      {/* Nested Routes within Group */}
      <Route index element={<GroupCalendar />} /> 
      <Route path="stats" element={<GroupStatistics />} />
      <Route path="squad" element={<GroupMembers />} />
    </Route>
    
    <Route path="find" element={<PublicMatches />} />
    <Route path="groups/:groupId/match/:matchId" element={<Match />} />
    <Route path="groups/:groupId/match/:matchId/edit" element={<CreateMatchForm />} />
    <Route path="groups/:groupId/create-match" element={<CreateMatchForm />} />
    <Route path="all-groups" element={<AllGroupsList/>} />


          </Route>
        </Routes>
      </Router>
      </UserProvider>
      </AppProvider>
    </ChakraProvider>
  );
}

export default App;
