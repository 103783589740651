import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";

/**
 * Custom hook to fetch and manage group data from Firestore.
 * @param {string} groupId - The ID of the group to fetch.
 */
const useAllGroups = (groupId) => {
    
    const [allGroups, setAllGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchGroups = async () => {
          try {
            setLoading(true);
            const groupsCollection = collection(db, "groups");
            const groupsSnapshot = await getDocs(groupsCollection);
            const groupsData = groupsSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setAllGroups(groupsData);
          } catch (err) {
            console.error("Error fetching groups:", err);
            setError("Failed to load groups. Please try again later.");
          } finally {
            setLoading(false);
          }
        };
    
        fetchGroups();
      }, []);


  
  return { allGroups, loading, error };
};

export default useAllGroups;
