import React, { useState, useEffect } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  Button,
  Input,
  Flex,
  Select,
  Spinner,
  useToast,
  Heading,
  Box,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import AsyncSelect from "react-select/async";
import { doc, updateDoc, addDoc, collection, getDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import useGroupMembers from "../../hooks/useGroupMembers";
import { useUserContext } from "../../context/UserContext";
import PlacesAutocomplete from "react-places-autocomplete";

const EditGroupDrawer = ({
  isOpen,
  onClose,
  groupId,
  group,
  isEditMode,
  isAdmin
}) => {

  const { currentUser, setGroups } = useUserContext();
  const { groupMembers } = useGroupMembers(groupId);
  
  const [groupName, setGroupName] = useState("");
  const [location, setLocation] = useState("");
  const [scheduledDay, setScheduledDay] = useState("");
  const [scheduledHour, setScheduledHour] = useState("");
  const [scheduledMinute, setScheduledMinute] = useState("");
  const [admins, setAdmins] = useState([]);
  const [saving, setSaving] = useState(false);
  const toast = useToast();
  const [city, setCity] = useState(""); 
  

  useEffect(() => {
    const fetchGroupData = async () => {
      if (isEditMode && groupId) {
        try {
          const groupRef = doc(db, "groups", groupId);
          const groupSnap = await getDoc(groupRef);
          if (groupSnap.exists()) {
            const groupData = groupSnap.data();

            // Update the state with the latest group data
            setGroupName(groupData.name || "");
            setLocation(groupData.location || "");
            setCity(groupData.city || "");
            setScheduledDay(groupData.scheduledDay || "");
            if (groupData.scheduledTime) {
              const [hour, minute] = groupData.scheduledTime.split(":");
              setScheduledHour(hour);
              setScheduledMinute(minute);
            }
            setAdmins(
              groupData.admin?.map((uid) => ({
                value: uid,
                label:
                  groupMembers?.find((member) => member.value === uid)?.label ||
                  "Unknown",
              })) || []
            );
          }
        } catch (error) {
          console.error("Error fetching group data:", error.message);
          toast({
            title: "Error",
            description: "Failed to fetch group data.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };

    if (isOpen) {
      fetchGroupData(); // Fetch data only when the Drawer is opened
    }
  }, [isOpen, isEditMode, groupId, groupMembers, toast]);

  const generateGroupCode = () => {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let code = "";
    for (let i = 0; i < 6; i++) {
      code += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return code;
  };
  

  const handleSaveGroup = async () => {
    if (!groupName || !location || !scheduledDay || !scheduledHour || !scheduledMinute) {
      toast({
        title: "Missing fields",
        description: "Please provide all required fields, including the city.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setSaving(true);
    try {
      const scheduledTime = `${scheduledHour}:${scheduledMinute}`;
      const currentUserUID = currentUser?.id;
      if (!currentUserUID) {
        toast({
          title: "Error",
          description: "Could not identify the current user.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setSaving(false);
        return;
      }

      if (isEditMode) {
        const groupRef = doc(db, "groups", groupId);
        await updateDoc(groupRef, {
          name: groupName,
          location,
          city: city || "",
          scheduledDay,
          scheduledTime,
          admin: Array.from(new Set([...admins.map((admin) => admin.value), currentUserUID])),
        });

        const updatedGroup = {
          ...group,
          name: groupName,
          location,
          city,
          scheduledDay,
          scheduledTime,
          admin: Array.from(new Set([...admins.map((admin) => admin.value), currentUserUID])),
        };

        setGroups((prevGroups) =>
          prevGroups.map((g) => (g.id === groupId ? updatedGroup : g))
        );

        toast({
          title: "Group updated!",
          description: `Group "${groupName}" updated successfully.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        const groupCode = generateGroupCode();
        const newGroup = {
          name: groupName,
          location,
          city,
          scheduledDay,
          scheduledTime,
          members: [currentUserUID],
          admin: [currentUserUID],
          code: groupCode,
        };

        const groupRef = await addDoc(collection(db, "groups"), newGroup);
        setGroups((prevGroups) => [
          ...prevGroups,
          { id: groupRef.id, ...newGroup },
        ]);

        toast({
          title: "Group created!",
          description: `Group "${groupName}" created successfully.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }

      onClose();
    } catch (error) {
      console.error("Error saving group:", error.message);
      toast({
        title: "Error",
        description: "Failed to save group data.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setSaving(false);
    }
  };
  
  
  const handleSelect = async (address) => {
    try {
      setLocation(address);
  
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status) => {
        if (status === "OK" && results[0]) {
          const extractedCity = results[0].address_components.find((component) =>
            component.types.includes("locality")
          )?.long_name;
  
          console.log("Extracted City:", extractedCity);
  
          if (extractedCity) {
            setCity(extractedCity);
          } else {
            console.warn("City not found in the address components.");
            setCity(""); // Set to an empty string if city is not found
          }
        } else {
          console.error("Geocode failed:", status);
          toast({
            title: "Geocode Error",
            description: `Failed to geocode address. Status: ${status}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      });
    } catch (error) {
      console.error("Error in geocoding:", error);
      toast({
        title: "Geocode Error",
        description: "An unexpected error occurred during geocoding.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  
  
  

  
  
  
  

  
  

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Heading>{isEditMode ? "Edit Group" : "Create Group"}</Heading>
        </DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody gap={8} as={Flex} flexDirection={"column"}>
          
            <FormControl>
            <FormLabel>Group name</FormLabel>
              <Input
              placeholder="Group name"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
            </FormControl>
            <FormControl>
  <FormLabel>Location</FormLabel>
  <PlacesAutocomplete
              value={location}
              onChange={setLocation}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <Input
                    {...getInputProps({
                      placeholder: "Enter a location",
                      className: "location-search-input",
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <Spinner size="sm" />}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      const style = suggestion.active
                        ? { backgroundColor: "#0D121C", cursor: "pointer", padding: "4px 8px"}
                        : { backgroundColor: '#202939', cursor: "pointer", padding: "4px 8px" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                          key={suggestion.placeId}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
</FormControl>

<FormControl>
  <FormLabel>City</FormLabel>
  <Input
    placeholder="Enter city manually if needed"
    value={city}
    onChange={(e) => setCity(e.target.value)} // Allow manual modification of city
  />
</FormControl>



           <FormControl>
           <FormLabel>Scheduled time</FormLabel>
            <Flex gap={2}>
              <Flex flex={1}>
                
                <Select
                placeholder="Day"
                value={scheduledDay}
                onChange={(e) => setScheduledDay(e.target.value)}
              >
                {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </Select></Flex>
              <Flex gap={2}>
              <Select
                placeholder="Hour"
                value={scheduledHour}
                onChange={(e) => setScheduledHour(e.target.value)}
              >
                {Array.from({ length: 24 }, (_, i) => (
                  <option key={i} value={String(i).padStart(2, "0")}>
                    {String(i).padStart(2, "0")}
                  </option>
                ))}
              </Select>
              <Select
                placeholder="Minute"
                value={scheduledMinute}
                onChange={(e) => setScheduledMinute(e.target.value)}
              >
                {["00", "15", "30", "45"].map((minute) => (
                  <option key={minute} value={minute}>
                    {minute}
                  </option>
                ))}
              </Select>
              </Flex>
            </Flex>
            </FormControl>

            

            {isEditMode && isAdmin && (
            <FormControl>
              <FormLabel>Group admins</FormLabel>
            <Box 
              width="100%"
              sx={{
                ".customSelect": {
                  width: "100%",
                  borderRadius: '0 !important'
                },
                ".customSelect__control": {
                  borderColor: "grey.800",
                  bg: "grey.800",
                  borderRadius: '0 !important',
                  h: "48px"
                },
                ".customSelect__input": {
                  bg: "transparent !important",
                  color: "white !important",
                },
                ".customSelect__single-value": {
                  color: "white !important",
                },
                ".customSelect__menu": {
                  borderRadius: '0 !important',
                  bg: 'grey.800',
                  m: 0
                },
                ".customSelect__menu-list": {
                  p: 0
                },
                ".customSelect__option:hover": {
                  bg: 'brand.500',
                }
              }}>
                <AsyncSelect
                className="customSelect"
                classNamePrefix="customSelect"
                isMulti
                defaultOptions={groupMembers}
                loadOptions={(inputValue) =>
                  Promise.resolve(
                    groupMembers.filter((member) =>
                      member.label.toLowerCase().includes(inputValue.toLowerCase())
                    )
                  )
                }
                value={admins}
                onChange={(selected) => setAdmins(selected || [])}
                placeholder="Set group admins"
              />
              </Box>
            </FormControl>
            )}
        </DrawerBody>
        <DrawerFooter gap={2}>
          
          <Button variant="ghost" onClick={onClose} ml={3}>
            Cancel
          </Button>
          <Button
            bg={"lightGreen.300"}
            onClick={handleSaveGroup}
            isDisabled={saving}
          >
            {saving ? <Spinner size="sm" /> : "Save"}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default EditGroupDrawer;
