import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";

const useGroupMatches = (groupId) => {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGroupMatches = async () => {
      if (!groupId) return;

      setLoading(true); // Start loading
      try {
        const matchesRef = collection(db, "groups", groupId, "matches");
        const matchesSnapshot = await getDocs(matchesRef);
        const groupMatches = matchesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          groupId, // Add groupId to matches for proper navigation
        }));
        setMatches(groupMatches);
      } catch (error) {
        console.error("Error fetching group matches:", error.message);
      } finally {
        setLoading(false);
      }
    };

    if (groupId) {
      fetchGroupMatches();
    }
  }, [groupId]);
  

  return { matches, loading };
};

export default useGroupMatches;
