import React, { useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { auth } from "../../firebaseConfig";
import {
  Flex,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Tabs,
  TabPanels,
  TabPanel,
  Text,
  Box,
  Tag
} from "@chakra-ui/react";
import Summary from "./Summary";
import MatchHeader from "./MatchHeader";
import { useUserContext } from "../../context/UserContext";
import ScoreAggregatorButtons from "./ScoreAggregatorButtons";
import Lineups from "./Lineups";
import PlayerProfileDrawer from "../PlayerProfileDrawer";
import useGroup from "../../hooks/useGroup";
import useMatchLogic from "../../hooks/useMatchLogic";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";

function Match() {
  const { groupId, matchId } = useParams();
  const { currentUser } = useUserContext();
  const {
    matchData,
    score,
    timer,
    isRunning,
    isMatchEnded,
    isDialogOpen,
    setIsDialogOpen,
    isAuthorized,
    cancelRef,
    handleStartMatch,
    handleGoal,
    setPendingGoals,
    cooldowns,
    addGoal,
    cancelPendingGoal,
    cancelPendingOwnGoal,
    handleEndMatch,
    handleResetMatch,
    pendingGoals,
    pendingOwnGoals,
    setPendingOwnGoals,
    addOwnGoal
  } = useMatchLogic(currentUser);

  const { group } = useGroup(groupId);
  const navigate = useNavigate();

  const [goals, setGoals] = useState({});
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  const handleEditTeams = () => {
    navigate(`/groups/${groupId}/match/${matchId}/edit`, {
      state: {
        initialTeam1Players: matchData.players.team1,
        initialTeam2Players: matchData.players.team2,
        teamColors: matchData.teamColors,
        location: matchData.location,
        scheduledTime: matchData.scheduledTime,
        playersPerTeam: {
          team1: matchData.players.team1.length,
          team2: matchData.players.team2.length,
        },
      },
    });
  };

  const isMatchDay = () => {
    if (!matchData.scheduledTime) return false;

    let matchDate;
    try {
      matchDate = new Date(matchData.scheduledTime);
      if (isNaN(matchDate.getTime())) {
        console.error(
          "Invalid matchData.scheduledTime:",
          matchData.scheduledTime
        );
        return false;
      }
    } catch (error) {
      console.error(
        "Error parsing matchData.scheduledTime:",
        matchData.scheduledTime,
        error
      );
      return false;
    }

    const today = new Date().toISOString().split("T")[0]; // YYYY-MM-DD
    const matchDay = matchDate.toISOString().split("T")[0];
    return today === matchDay;
  };

  const [selectedPlayerId, setSelectedPlayerId] = useState(null);
  const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
  const openPlayerModal = (playerId) => {
    setSelectedPlayerId(playerId);
    setIsPlayerModalOpen(true);
  };

  const closePlayerModal = () => {
    setIsPlayerModalOpen(false);
    setSelectedPlayerId(null);
  };

  const currentUserUid = auth.currentUser?.uid;
  const isAdmin = group?.admin?.includes(currentUserUid);
  const isMember = group?.members?.includes(currentUserUid);
  const isCreator = matchData?.createdBy === currentUserUid;

  const handleCancelMatch = async () => {
    try {
      const matchRef = doc(db, "groups", groupId, "matches", matchId);
      await deleteDoc(matchRef);
      navigate(`/groups/${groupId}`); // Redirect to the group's page after cancellation
    } catch (error) {
      console.error("Error cancelling match:", error.message);
    } finally {
      setIsCancelDialogOpen(false);
    }
  };

  if (!matchData) {
    return <p>Loading match...</p>;
  }

  return (
    <Tabs
      isFitted
      variant="line"
      colorScheme="blue"
      w={"100%"}
      flex={1}
      display={"flex"}>
      <Flex
        mt={{ base: 0, md: -4 }}
        direction="column"
        align="center"
        w={"100%"}
        flex={1}
        justifyContent={"space-between"}>
        
        {matchData.scheduledTime && (
          <Flex color={"white"} mb={4} justifyContent={"space-between"} w={"100%"} gap={2}>
            {matchData?.groupName && isMember ? (
              <Tag as={Link} to={`/groups/${groupId}`} colorScheme="lightGreen" textTransform={"uppercase"} borderRadius={0}>
                {matchData?.groupName}
              </Tag>
            ) : (
              <Tag colorScheme="lightGreen" textTransform={"uppercase"} borderRadius={0}>
                {matchData?.groupName}
              </Tag>
            )}
            <Text color={"white"}>
              {new Date(matchData.scheduledTime).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
              , {matchData.city}
            </Text>
          </Flex>
        )}

        <MatchHeader
          matchData={matchData}
          score={score}
          isRunning={isRunning}
          timer={timer}
          isMatchEnded={isMatchEnded}
          goals={goals}
          players={matchData.players}
          handleEditTeams={handleEditTeams}
          setIsDialogOpen={setIsDialogOpen}
          isAuthorized={isAuthorized}
        />

        <Flex direction="column" align="center" gap={4} w="100%" flex={1} pt={4}>
          {!isMatchEnded &&
            isRunning === false &&
            isAuthorized &&
            isMatchDay() && (
              <Box bg="grey.950" w="100%">
                <Button
                  onClick={handleStartMatch}
                  zIndex={10}
                  bg="lightGreen.300"
                  color="grey.950"
                  w="100%">
                  <Flex gap={1}>Start Match</Flex>
                </Button>
              </Box>
            )}

          {isRunning && !isMatchEnded && isAuthorized && (
            <Box bg="grey.950" w="100%">
              <Button
                onClick={() => setIsDialogOpen(true)}
                bg="red.500"
                color="white"
                zIndex={10}
                w="100%">
                <Flex gap={1}>End Match</Flex>
              </Button>
            </Box>
          )}

          {/* Cancel Match Button visible only for the match creator and if match is not running and not ended */}
          {isCreator && !isRunning && !isMatchEnded && (
            <Box bg="grey.950" w="100%">
              <Button
                onClick={() => setIsCancelDialogOpen(true)}
                bg="red.600"
                color="white"
                zIndex={10}
                w="100%">
                <Flex gap={1}>Cancel Match</Flex>
              </Button>
            </Box>
          )}

          <TabPanels flex={1}>
            <TabPanel p={0} flex={1} display={"flex"}>
              {!matchData.startTime ? (
                <Lineups
                  players={matchData.players}
                  openPlayerModal={openPlayerModal}
                />
              ) : (
                <ScoreAggregatorButtons
                  players={matchData.players}
                  goals={goals}
                  handleGoal={handleGoal}
                  isMatchEnded={isMatchEnded}
                  isRunning={isRunning}
                  isAuthorized={isAuthorized}
                  pendingGoals={pendingGoals}
                  setPendingGoals={setPendingGoals}
                  pendingOwnGoals={pendingOwnGoals}
                  setPendingOwnGoals={setPendingOwnGoals}
                  cooldowns={cooldowns}
                  addGoal={addGoal}
                  addOwnGoal={addOwnGoal}
                  cancelPendingGoal={cancelPendingGoal}
                  cancelPendingOwnGoal={cancelPendingOwnGoal}
                />
              )}
            </TabPanel>
            <TabPanel p={0}>
              {matchData.players ? (
                <Summary players={matchData.players} endTime={matchData.endTime} />
              ) : (
                <Text>No data available for summary.</Text>
              )}
            </TabPanel>
          </TabPanels>
        </Flex>

        <AlertDialog
          isOpen={isDialogOpen}
          leastDestructiveRef={cancelRef}
          onClose={() => setIsDialogOpen(false)}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                End Match
              </AlertDialogHeader>
              <AlertDialogBody>
                Do you want to end the match or reset it? Resetting will erase
                all scores and goals, and the match will be marked as not
                started. This action is irreversible.
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  ref={cancelRef}
                  variant="outline"
                  onClick={() => setIsDialogOpen(false)}>
                  Cancel
                </Button>
                <Button bg={"blue.500"} onClick={handleResetMatch} ml={3}>
                  Reset Match
                </Button>
                <Button bg={"red.500"} onClick={handleEndMatch} ml={3}>
                  End Match
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

        {/* Cancel Match Confirmation Dialog */}
        <AlertDialog
          isOpen={isCancelDialogOpen}
          leastDestructiveRef={cancelRef}
          onClose={() => setIsCancelDialogOpen(false)}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Cancel Match
              </AlertDialogHeader>
              <AlertDialogBody>
                Are you sure you want to cancel this match? This action is
                irreversible, and all match data will be permanently deleted.
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  ref={cancelRef}
                  variant="outline"
                  onClick={() => setIsCancelDialogOpen(false)}>
                  No, Go Back
                </Button>
                <Button bg={"red.600"} onClick={handleCancelMatch} ml={3}>
                  Yes, Cancel Match
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Flex>

      <PlayerProfileDrawer
        isOpen={isPlayerModalOpen}
        onClose={closePlayerModal}
        playerId={selectedPlayerId}
        groupId={groupId}
        isAdmin={isAdmin}
      />
    </Tabs>
  );
}

export default Match;
