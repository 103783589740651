import { useState, useEffect } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebaseConfig";

const usePlayerMatches = (playerId) => {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!playerId) {
      setMatches([]);
      setLoading(false);
      return;
    }

    const fetchPlayerMatches = async () => {
      setLoading(true);
      setError(null);

      try {
        // Récupérer tous les groupes
        const groupsRef = collection(db, "groups");
        const groupSnapshots = await getDocs(groupsRef);

        const groupIds = groupSnapshots.docs.map((doc) => doc.id);
        if (groupIds.length === 0) {
          setMatches([]);
          setLoading(false);
          return;
        }

        // Récupérer les matchs pour chaque groupe
        const allMatches = [];
        for (const groupId of groupIds) {
          const matchesRef = collection(db, "groups", groupId, "matches");
          const matchesQuery = query(
            matchesRef,
            where("playerIds", "array-contains", playerId) // Filtrer par playerId
          );

          const matchesSnapshot = await getDocs(matchesQuery);
          const groupMatches = matchesSnapshot.docs.map((doc) => ({
            id: doc.id,
            groupId,
            ...doc.data(),
          }));

          allMatches.push(...groupMatches);
        }

        setMatches(allMatches);
      } catch (err) {
        console.error("Erreur lors de la récupération des matchs :", err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayerMatches();
  }, [playerId]);


  return { matches, loading, error };
};

export default usePlayerMatches;
