import React, { useState } from "react";
import { auth, db } from "../firebaseConfig";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import {
  Button,
  Input,
  Flex,
  Box,
  Text,
  Divider,
  AbsoluteCenter,
  SimpleGrid,
  Image,
  Heading,
} from "@chakra-ui/react";
import CountrySelect from "./form/CountrySelect";
import { FcGoogle } from "react-icons/fc";
import loginImage from "../img/loginImage.webp";
import { Link } from "react-router-dom";
import { useUserContext } from "../context/UserContext";

function Auth({ onAuthSuccess }) {
  const { setCurrentUser } = useUserContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState(null); // Utiliser un objet pour le pays
  const [birthday, setBirthday] = useState("");
  const [isSignUp, setIsSignUp] = useState(true);

  const handleAuth = async () => {
    try {
      if (!isSignUp) {
        // Create the user with Firebase Authentication
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );

        // Add the user to the "players" collection in Firestore
        const user = userCredential.user;
        const displayName = `${firstName.trim()} ${lastName.trim()}`; // Combine first and last name

        const userDoc = {
          email: user.email,
          uid: user.uid,
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          displayName: displayName,
          country: country?.label || "Not specified",
          countryCode: country?.value || "N/A",
          birthday: birthday,
          createdAt: new Date(),
        };

        await setDoc(doc(db, "players", user.uid), userDoc);
      } else {
        // Sign in an existing user
        await signInWithEmailAndPassword(auth, email, password);
      }

      onAuthSuccess(); // Notify parent of successful authentication
    } catch (error) {
      alert(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
  
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
  
      // Split the displayName into firstName and lastName
      let firstName = "";
      let lastName = "";
      if (user.displayName) {
        const nameParts = user.displayName.split(" ");
        firstName = nameParts[0] || ""; // First part of the name
        lastName = nameParts.slice(1).join(" ") || ""; // Remaining parts
      }
  
      // Check if the user already exists in Firestore
      const userRef = doc(db, "players", user.uid);
      const userSnap = await getDoc(userRef);
  
      if (!userSnap.exists()) {
        // If the user doesn't exist, create a new user document
        const userDoc = {
          email: user.email,
          uid: user.uid,
          displayName: user.displayName,
          firstName, // Save split firstName
          lastName, // Save split lastName
          profileImage: user.photoURL || "",
          country: "Luxembourg",
          countryCode: "lu",
          createdAt: new Date(),
        };
        await setDoc(userRef, userDoc);
      }
  
      // Immediately fetch the user's data from Firestore and update the context
      const updatedUserSnap = await getDoc(userRef);
      if (updatedUserSnap.exists()) {
        setCurrentUser({ id: user.uid, ...updatedUserSnap.data() }); // Force update UserContext state
      }
  
      onAuthSuccess(); // Notify parent of successful authentication
    } catch (error) {
      console.error("Error with Google Sign-In:", error.message);
      alert("Failed to sign in with Google. Please try again.");
    }
  };
  

  return (
    <SimpleGrid
      columns={{ base: 1, lg: 2 }}
      minHeight="100dvh"
      w={"100vw"}
      overflow={"hidden"}>
      <Flex
        justifyContent={{ base: "flex-start", md: "center" }}
        align={"center"}
        py={{ base: 8, md: 16 }}
        px={{ base: 8, md: 24 }}
        flex={1}
        w={"100%"}
        direction={"column"}
        gap={8}>

      <Flex maxW={"420px"} w={"100%"} gap={{ base: 8, xl: 16 }} direction={"column"}>

        <Heading
          fontSize="2xl"
          fontWeight="bold"
          fontFamily={"Audiowide"}
          color={"white"}>
          FUTIX
        </Heading>

        <Flex direction="column" align="flex-start" gap={4} w={"100%"}>
          <Box as={Link} to="/"></Box>

          <Button
            w={"100%"}
            size="lg"
            fontWeight="500"
            fontSize="md"
            variant="outline"
            leftIcon={<FcGoogle />}
            onClick={handleGoogleSignIn}>
            Login with Google
          </Button>

          <Box position="relative" py={6} w={"100%"}>
            <Divider bg={"grey.800"} w={"100%"} />
            <AbsoluteCenter px="4" bg={"grey.950"}>
              or
            </AbsoluteCenter>
          </Box>

          {!isSignUp && (
            <>
              <Input
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <Input
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <Input
                type="date"
                value={birthday}
                onChange={(e) => setBirthday(e.target.value)}
              />
              <CountrySelect onChange={setCountry} isDisabled={false} />
            </>
          )}
          <Input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Button onClick={handleAuth} bg={"lightGreen.300"} w="100%">
            {!isSignUp ? "Create Account" : "Login"}
          </Button>

          <Text
            fontSize={"sm"}
            onClick={() => setIsSignUp(!isSignUp)}
            cursor={"pointer"}
            _hover={{ textDecoration: "underline" }}
            w={"100%"}
            textAlign={"center"}>
            {!isSignUp
              ? "Already have an account? Login"
              : "Don’t have an account? Create an account"}
          </Text>
        </Flex>
        </Flex>
      </Flex>
     

      <Flex display={{ base: "none", lg: "flex" }} position={'relative'} p={16} gap={2}>
        <Image
          src={loginImage}
          alt="Login Image"
          h={"100%"}
          w={"100%"}
          position={"absolute"}
          top={0}
          left={0}
          objectFit={"cover"}
          objectPosition={"center"}
        />
        <Box zIndex={1}>
        <Heading zIndex={1} maxWidth={"420px"} size={"4xl"} style={{ mixBlendMode: "color" }}>
        “To win you have to score one more goal than your opponent.”
        </Heading>
        <Text color={"white"}>— Johan Cruyff</Text>
        </Box>
      </Flex>


      
    </SimpleGrid>
  );
}

export default Auth;
