import React, { useState, useEffect } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Button,
  Flex,
  Text,
  Spinner,
  Stat,
  StatLabel,
  Image,
  Heading,
  StatNumber,
  useToast,
  DrawerCloseButton,
  Avatar,
} from "@chakra-ui/react";
import {
  doc,
  getDoc,
  updateDoc,
  arrayRemove,
} from "firebase/firestore";
import { db, auth } from "../firebaseConfig";
import placeholder from "../img/playerProfilePlaceholder.jpg";
import Calendar from "./Calendar";
import { useUserContext } from "../context/UserContext";
import usePlayerMatches from "../hooks/usePlayerMatches";

function PlayerProfileDrawer({ isOpen, onClose, playerId, groupId, isAdmin }) {
  
  const { setGroups } = useUserContext();
  const [playerData, setPlayerData] = useState(null);
  const [playerStats, setPlayerStats] = useState({
    matchesPlayed: 0,
    goalsScored: 0,
    wins: 0,
    winRate: 0,
    goalsPerGame: 0,
  });
  const [loading, setLoading] = useState(true);
  const [age, setAge] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [isMember, setIsMember] = useState(false);
  const toast = useToast();

  // Utiliser le hook usePlayerMatches pour récupérer les matchs du joueur
  const { matches, loading: matchesLoading } = usePlayerMatches(playerId);

  useEffect(() => {
    const fetchPlayerData = async () => {
      if (!playerId) return;

      setLoading(true);

      try {
        const currentUser = auth.currentUser;
        setCurrentUserId(currentUser?.uid);

        const playerRef = doc(db, "players", playerId);
        const playerSnap = await getDoc(playerRef);

        if (playerSnap.exists()) {
          const playerInfo = playerSnap.data();

          // Fetch group data to check if the player is a member
          if (groupId) {
            const groupRef = doc(db, "groups", groupId);
            const groupSnap = await getDoc(groupRef);

            if (groupSnap.exists()) {
              const groupData = groupSnap.data();
              setIsMember(groupData.members?.includes(playerId));
            }
          }

          // Filtrer les matchs pour ne conserver que ceux qui ont un endTime
          const completedMatches = matches.filter((match) => match.endTime);

          // Utiliser les matchs filtrés pour calculer les statistiques du joueur
          let totalMatches = completedMatches.length;
          let totalGoals = 0;
          let totalWins = 0;

          for (const match of completedMatches) {
            // Vérifier si le joueur a participé
            const playedInTeam1 = match.players?.team1?.some(
              (player) => player.uid === playerId
            );
            const playedInTeam2 = match.players?.team2?.some(
              (player) => player.uid === playerId
            );

            if (playedInTeam1 || playedInTeam2) {
              // Vérifier si le joueur a gagné
              if (
                (playedInTeam1 && match.score?.team1 > match.score?.team2) ||
                (playedInTeam2 && match.score?.team2 > match.score?.team1)
              ) {
                totalWins++;
              }

              // Compter le nombre de buts marqués par le joueur
              const teamKey = playedInTeam1 ? "team1" : "team2";
              const playerStats = match.players?.[teamKey]?.find(
                (p) => p.uid === playerId
              );

              if (playerStats) {
                totalGoals += playerStats.goals || 0;
              }
            }
          }

          // Calculer les statistiques dérivées
          const winRate =
            totalMatches > 0
              ? ((totalWins / totalMatches) * 100).toFixed(2)
              : 0;
          const goalsPerGame =
            totalMatches > 0 ? (totalGoals / totalMatches).toFixed(2) : 0;

          // Mettre à jour les états locaux
          setPlayerData(playerInfo);
          setAge(calculateAge(playerInfo.birthday));
          setCountryCode((playerInfo.countryCode || "").toLowerCase());
          setPlayerStats({
            matchesPlayed: totalMatches,
            goalsScored: totalGoals,
            wins: totalWins,
            winRate,
            goalsPerGame,
          });
        }
      } catch (error) {
        console.error("Error fetching player data:", error.message);
      } finally {
        setLoading(false);
      }
    };

    // N'attendez d'appeler fetchPlayerData que si les matchs sont chargés
    if (!matchesLoading) {
      fetchPlayerData();
    }
  }, [playerId, groupId, matches, matchesLoading]);

  const calculateAge = (birthday) => {
    if (!birthday) return "N/A"; // Retourne "N/A" si la date de naissance est absente

    const birthDate = new Date(birthday);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Vérifiez si l'anniversaire est passé cette année
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleRemovePlayerFromGroup = async () => {
    if (!groupId || !playerId) return;
  
    try {
      const groupRef = doc(db, "groups", groupId);
  
      // Remove the player from Firestore
      await updateDoc(groupRef, {
        members: arrayRemove(playerId),
        admin: arrayRemove(playerId), // Remove player from admin list if applicable
      });
  
      // Update the local state for groups
      setGroups((prevGroups) =>
        prevGroups.filter((group) => group.id !== groupId)
      );
  
      toast({
        title: "Player removed",
        description: "The player has been successfully removed from the group.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
  
      onClose(); // Close the drawer after removing the player
    } catch (error) {
      console.error("Error removing player:", error.message);
      toast({
        title: "Error",
        description: "Failed to remove the player from the group.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="lg" placement="right">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton zIndex={1} />
        <DrawerBody p={0}>
          {loading ? (
            <Flex justify="center" align="center" height="200px">
              <Spinner size="lg" />
            </Flex>
          ) : playerData ? (
            <Flex
              direction={"column"}
              bg={"brand.700"}
              position={"relative"}
              w={"100%"}
              overflow={"hidden"}>
              <Flex
                p={{ base: 4, md: 8 }}
                justify="space-between"
                align={{ base: "flex-start", md: "flex-start" }}
                direction={{ base: "column", md: "row" }}
                gap={16}>
                <Flex
                  direction={{ base: "column", lg: "row" }}
                  gap={8}
                  w={"100%"}>
                  <Flex gap={8} flex={1}>
                    <Avatar
                      src={playerData.profileImage || placeholder}
                      alt="Profile"
                      w={{ base: "82px", md: "132px" }}
                      h={{ base: "82px", md: "132px" }}
                      border={"3px solid"}
                      borderColor={"brand.800 !important"}
                      borderRadius={"0"}
                    />
                    <Flex direction="column" gap={6} color="white" zIndex="1">
                      <Flex direction={"column"} gap={0}>
                        <Heading size="xl" fontWeight={"500"} lineHeight={1}>
                          {playerData.firstName}
                        </Heading>
                        <Flex gap={4} align={"center"}>
                          <Heading
                            lineHeight={1}
                            size="3xl"
                            color={"lightGreen.300"}
                            textTransform={"uppercase"}>
                            {playerData.lastName}
                          </Heading>
                          <Image
                            src={`https://flagcdn.com/w80/${countryCode}.png`}
                            style={{ width: "32px", height: "32px" }}
                            borderRadius={"3px"}
                          />
                        </Flex>
                      </Flex>
                      <Flex direction="row" gap={{ base: 6, md: 8 }}>
                        <Stat>
                          <StatLabel
                            color={"brand.300"}
                            textTransform={"uppercase"}
                            fontSize={"xs"}>
                            Age
                          </StatLabel>
                          <StatNumber fontWeight={"800"}>{age}</StatNumber>
                        </Stat>
                        <Stat>
                          <StatLabel
                            color={"brand.300"}
                            textTransform={"uppercase"}
                            fontSize={"xs"}>
                            Games
                          </StatLabel>
                          <StatNumber fontWeight={"800"}>
                            {playerStats.matchesPlayed}
                          </StatNumber>
                        </Stat>
                        <Stat>
                          <StatLabel
                            color={"brand.300"}
                            textTransform={"uppercase"}
                            fontSize={"xs"}>
                            Wins
                          </StatLabel>
                          <StatNumber fontWeight={"800"}>
                            {playerStats.wins}
                          </StatNumber>
                        </Stat>
                        <Stat>
                          <StatLabel
                            color={"brand.300"}
                            textTransform={"uppercase"}
                            fontSize={"xs"}>
                            Goals
                          </StatLabel>
                          <StatNumber fontWeight={"800"}>
                            {playerStats.goalsScored}
                          </StatNumber>
                        </Stat>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          ) : (
            <Flex justify="center" align="center" height="200px">
              <Text>No player data available</Text>
            </Flex>
          )}
          {isAdmin && isMember && currentUserId !== playerId && (
            <Button colorScheme="red" onClick={handleRemovePlayerFromGroup} w={"100%"}>
              Remove from Group
            </Button>
          )}
          <Flex p={8} w={"100%"}>
            <Calendar playerId={playerId} grid={1} showCompletedOnly={true} />
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default PlayerProfileDrawer;
