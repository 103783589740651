import {
  Flex,
  Box,
  Heading,
  Spinner,
  Avatar,
  HStack,
  Button,
} from "@chakra-ui/react";
import { Link, NavLink } from "react-router-dom";
import { useUserContext } from "../../context/UserContext";
import placeholder from "../../img/playerProfilePlaceholder.jpg";
import NotificationBell from "./NotificationBell";

function Header({ handleOpenDrawer }) {
  const { currentUser, loading } = useUserContext();

  if (loading) {
    return (
      <Flex justify="center" align="center" h="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <>
      <Flex
        py={{ base: 0, md: 6 }}
        px={{ base: 2, md: 8 }}
        align="center"
        justify="space-between"
        bg={{ base: "transparent", md: "grey.800" }}
      >
        <Box as={Link} to="/">
          <Heading
            fontSize="2xl"
            fontWeight="bold"
            fontFamily={"Audiowide"}
            color={"white"}
          >
            FUTiX
          </Heading>
        </Box>

        <HStack
          gap={0}
          sx={{
            a: { fontSize: "xl", color: "white" },
            "a.active": { color: "pink.400" },
            "a:not(.active) svg": { display: "none" },
          }}
        >
          <Button as={NavLink} to="/profile" end variant={"ghost"}>
            <Box w="20px">
              <svg width="11" height="8" fill="none">
                <path
                  fill="#FF7FDE"
                  d="M2.28.702A1 1 0 0 1 3.236 0H9.14a1 1 0 0 1 .954 1.298l-1.875 6A1 1 0 0 1 7.265 8H1.36a1 1 0 0 1-.954-1.298l1.875-6Z"
                ></path>
              </svg>
            </Box>
            Groups
          </Button>
          <Button as={NavLink} to="/profile/calendar" variant={"ghost"}>
            <Box as="span" w="20px">
              <svg width="11" height="8" fill="none">
                <path
                  fill="#FF7FDE"
                  d="M2.28.702A1 1 0 0 1 3.236 0H9.14a1 1 0 0 1 .954 1.298l-1.875 6A1 1 0 0 1 7.265 8H1.36a1 1 0 0 1-.954-1.298l1.875-6Z"
                ></path>
              </svg>
            </Box>
            Calendar
          </Button>

          <Button as={NavLink} to="/find" variant={"ghost"}>
            <Box as="span" w="20px">
              <svg width="11" height="8" fill="none">
                <path
                  fill="#FF7FDE"
                  d="M2.28.702A1 1 0 0 1 3.236 0H9.14a1 1 0 0 1 .954 1.298l-1.875 6A1 1 0 0 1 7.265 8H1.36a1 1 0 0 1-.954-1.298l1.875-6Z"
                ></path>
              </svg>
            </Box>
            Find a match
          </Button>

          {currentUser?.role === "superAdmin" && (
            <Button as={NavLink} to="/all-groups" variant={"ghost"}>
              <Box as="span" w="20px">
                <svg width="11" height="8" fill="none">
                  <path
                    fill="#FF7FDE"
                    d="M2.28.702A1 1 0 0 1 3.236 0H9.14a1 1 0 0 1 .954 1.298l-1.875 6A1 1 0 0 1 7.265 8H1.36a1 1 0 0 1-.954-1.298l1.875-6Z"
                  ></path>
                </svg>
              </Box>
              All groups
            </Button>
          )}
        </HStack>

        <HStack spacing={2}>
          <NotificationBell />

          <Avatar
            src={currentUser?.profileImage || placeholder} // Fallback image
            alt="Profile"
            borderRadius={"full"}
            w="42px"
            h="42px"
            onClick={handleOpenDrawer}
            cursor="pointer"
          />
        </HStack>
      </Flex>
    </>
  );
}

export default Header;
