import { Flex, Heading } from "@chakra-ui/react";

function TeamLineup({ teamPlayers, justifyContent, openPlayerModal }) {
  return (
    <Flex direction="column" align="center" flex="1" gap={1}>
      {teamPlayers?.map((player, index) => (
        <Flex
          bg={"grey.800"}
          key={index}
          borderRadius={0}
          w={"100%"}
          justifyContent={justifyContent}
          gap={2}
          py={4}
          px={4}
          cursor={player.uid ? "pointer" : "default"}
          onClick={() => player.uid && openPlayerModal(player.uid)}
        >
          <Flex
            color={"white"}
            gap={2}
            justify={justifyContent}
            w={"100%"}
            align={"center"}
          >
            <Heading as="div" color={"white"} fontSize={"xl"} gap={2}>
              {player.name}
            </Heading>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
}

export default TeamLineup;
