import React, { useState } from "react";
import {
  GridItem,
  Flex,
  Badge,
  Heading,
  Text,
  Box,
  Tag,
  TagLabel,
  Button,
  HStack,
  IconButton,
  Link,
  Image,
  TagLeftIcon,
} from "@chakra-ui/react";
import { MapPin, User } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import useMatchActions from "../hooks/useMatchActions";
import WazeIcon from "../img/waze.svg";

function MatchListing({ match, playerId, onRemoveMatch }) {
  const { isJoined, canCancel, handleCancelParticipation, handleJoinMatch } = useMatchActions(match);
  const navigate = useNavigate();
  const { formatScheduledTime } = useAppContext();

  // Local state for dynamic updates
  const [matchState, setMatchState] = useState(match);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "N/A";
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleJoin = async (e) => {
    e.stopPropagation(); // Prevent triggering the navigation
    const updatedMatch = await handleJoinMatch({
      groupId: matchState.groupId,
      matchId: matchState.id, // Ensure the match ID is passed
    });
  
    if (updatedMatch) {
      setMatchState({ ...updatedMatch, id: matchState.id }); // Retain ID
    }
  };
  
  const handleCancel = async (e) => {
    e.stopPropagation();
    const updatedMatch = await handleCancelParticipation();
    if (updatedMatch) {
      setMatchState({ ...updatedMatch, id: matchState.id });
    }
  };
  
  
  

  const getWazeLink = (address) => {
    return `https://waze.com/ul?q=${encodeURIComponent(matchState.location)}`;
  };

  const registeredPlayers =
    (matchState?.players?.team1?.length || 0) + (matchState?.players?.team2?.length || 0);
  const totalPlayers =
    (matchState?.playerCount?.team1 || 0) + (matchState?.playerCount?.team2 || 0);

  return (
    <GridItem
      key={matchState.id}
      bg="grey.800"
      p={4}
      position="relative"
      _hover={{ bg: "grey.900", cursor: "pointer" }}
      onClick={() =>
        navigate(
          matchState.groupId
            ? `/groups/${matchState.groupId}/match/${matchState.id}`
            : `/players/${playerId}/match/${matchState.id}`
        )
      }
    >
      <Flex direction="column" gap={4}>
        <Flex gap={4} align="center" position={"relative"}>
          <Badge colorScheme="green" size="sm">
            {formatDate(matchState.scheduledTime || matchState.startTime)}
          </Badge>
          <Flex align="center" gap={1}>
            <MapPin size={14} color="#AAA" />
            <Text fontSize="sm" color={"grey.400"}>
              {matchState.city || "N/A"}
            </Text>
          </Flex>

          {matchState.endTime && matchState.playerCount && (
            <Tag>
              <TagLabel>
                {matchState?.playerCount?.team1} vs {matchState?.playerCount?.team2}
              </TagLabel>
            </Tag>
          )}

          {!matchState.endTime && matchState.playerCount && (
            <Tag>
              <TagLeftIcon as={User} color="grey.400" />
              <TagLabel>
                {registeredPlayers} / {totalPlayers}
              </TagLabel>
            </Tag>
          )}
        </Flex>

        <Flex
          gap={4}
          alignItems={"flex-end"}
          justifyContent={"space-between"}
          flex={1}
        >
          {matchState.startTime ? (
            <Heading size="2xl" color="white">
              {matchState.score?.team1 || 0} - {matchState.score?.team2 || 0}
            </Heading>
          ) : (
            <Heading size="2xl" color="white">{formatScheduledTime(matchState.scheduledTime)}</Heading>
          )}

          {matchState.startTime && !matchState.endTime && (
            <Badge
              colorScheme="red"
              size="sm"
              display={"flex"}
              gap={1}
              alignItems={"center"}
            >
              <Box
                className="blink"
                bg={"red.500"}
                h={2}
                w={2}
                borderRadius={"full"}
              />
              Live
            </Badge>
          )}

          {!isJoined && !match.endTime && !match.startTime && (
            <Button
              onClick={handleJoin}
              bg={"lightGreen.300"}
              size={"sm"}
            >
              Join
            </Button>
          )}

          {!matchState.startTime && isJoined && (
            <HStack spacing={2}>
              {matchState?.location && (
                <IconButton
                  as={Link}
                  href={getWazeLink(matchState?.location)}
                  size="sm"
                  target="_blank"
                  borderRadius={2}
                  icon={<Image src={WazeIcon} alt="Waze" />}
                  aria-label="Navigate with Waze"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              )}
              <Button
                onClick={handleCancel}
                colorScheme="red"
                isDisabled={!canCancel()}
                size={"sm"}
              >
                Cancel Participation
              </Button>
            </HStack>
          )}

          {matchState.startTime && matchState.endTime && (
            <Badge colorScheme="brand" size="sm">
              Ended
            </Badge>
          )}
        </Flex>
      </Flex>
    </GridItem>
  );
}

export default MatchListing;
