import { background, extendTheme } from "@chakra-ui/react";
import useColors from "./useColors";

const useTheme = () => {
  
  const breakpoints = {
    "2xl": "1920px",
    xl: "1281px",
    lg: "960px", 
    base: "0px",
  };
  
  const { colors } = useColors();
    

  // Extend Chakra components
  const components = {
    Button: {
      variants: {
        solid: {
          fontFamily: "InsatiableDisplay-BoldCondensed, sans-serif",
          borderRadius: "0px",
        },
        ghost: {
          fontFamily: "InsatiableDisplay-BoldCondensed, sans-serif",
          color: 'white',
          borderRadius: "0px",
          bg: 'transparent',
          _hover: {
            bg: 'var(--grey100)',
          },
          _active: {
            bg: 'brand.50',
          },
        },
        outline: {
          fontFamily: "InsatiableDisplay-BoldCondensed, sans-serif",
          color: 'white',
          borderRadius: "0px",
          borderColor: 'grey.200',
          _hover: {
            bg: 'rgba(0, 0, 0, 0.15)',
          },
        },
        link: {
          color: 'var(--grey700)',
          _hover: {
            color: 'brand.400',
          },
        },
      },
      defaultProps: {
        colorScheme: 'brand',
        size: 'lg',
      },
    },
    Heading: {
      baseStyle: {
        fontFamily: "InsatiableDisplay-BoldCondensed, sans-serif",
        textTransform: "uppercase",
      },
    },
    Text: {
      baseStyle: {
        color: 'grey.500',
      },
    },
    Switch: {
      baseStyle: {
        track: {
          _checked: {
            bg: 'brand.700',
          },
        },
      },
      defaultProps: {
        colorScheme: 'brand',
        size: 'lg',
      },
    },
    Input: {
      baseStyle: {
        field: {
          _focus: {
            borderColor: 'brand.500',
            boxShadow: '0 0 0 2px #f25c54',
          },
          _focusVisible: {
            borderColor: 'brand.500',
            boxShadow: '0 0 0 2px #f25c54',
          },
          _hover: {
            borderColor: 'brand.300',
          },
        },
      },
      defaultProps: {
        focusBorderColor: 'brand.500',
        colorScheme: 'brand',
        size: 'lg',
        borderRadius: 0
      },
    },
    
    Checkbox: {
      baseStyle: {
        control: {
          borderWidth: '1px',
          borderRadius: '3px',
          _checked: {
            bg: 'brand.400',
            borderColor: 'brand.400',
            color: 'white', // Checkmark color
          },
        },
      },
      defaultProps: {
        colorScheme: 'brand',
      },
    },
    Textarea: {
      baseStyle: {
        height: "180px !important",
        fontSize: "14px",
        border: 'none',
        boxShadow: '0 0 0 1px var(--borders)',
        padding: 4,
        _focus: {
          borderColor: 'brand.400', // Focus border color
          boxShadow: '0 0 0 2px #f25c54',
        },
        _hover: {
          borderColor: 'brand.300', // Optional hover state
        },
      },
      defaultProps: {
        focusBorderColor: 'brand.400', // Ensure this is applied globally
        colorScheme: 'brand',
        size: 'lg',

      },
    },
    Select: {
      baseStyle: {
        field: {
          backgroundColor: 'grey.800 !important', // Couleur de fond par défaut
          color: 'white',
          border: 'none !important',
          borderRadius: '0px', 
          _focus: {
            borderColor: 'brand.400', // Bordure en focus
            boxShadow: '0 0 0 2px #f25c54', // Ombre en focus
          },
          _hover: {
            borderColor: 'brand.300', // Bordure au survol
          },
          _disabled: {
            bg: 'grey.900', // Couleur de fond quand désactivé
            cursor: 'not-allowed',
          },
        },
      },
      defaultProps: {
        size: 'lg', // Taille par défaut
        focusBorderColor: 'brand.400', // Bordure par défaut au focus
      },
    },
  };

  const styles = {
    global: {
      body: {
        fontFamily: "GT America, sans-serif",
        fontWeight: "400",
        bg: "grey.950",
      },
      'input': {
      background: 'grey.800 !important', // Use global styles to enforce the background color
      color: 'white',
      border: 'none !important',
      boxShadow: 'none',
      borderRadius: '0 !important',
    },
    '.chakra-modal__overlay': {
      bg: 'rgba(0, 0, 0, 0.9) !important',
    },
    '.chakra-modal__content-container': {
      padding: 4
    }
    },
  };


  const theme = extendTheme({ 
    
    config: {
      initialColorMode: "dark",
      useSystemColorMode: false,
    },
    breakpoints, colors, components, styles });

  return {
    breakpoints,
    theme,
    colors,
  };
};

export default useTheme;