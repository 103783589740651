// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBEMM0ayMN_GB3JvXEQnXtM_1Qz6JT0dwI",
  authDomain: "futixapp.firebaseapp.com",
  projectId: "futixapp",
  storageBucket: "futixapp.firebasestorage.app",
  messagingSenderId: "129579372075",
  appId: "1:129579372075:web:288749078713ec66f5a46e"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialisation de Firestore
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);


export { db, auth, storage };