import React, { useState, useEffect } from "react";
import {
  Flex,
  Button,
  Grid,
  GridItem,
  Heading,
  IconButton,
  RadioGroup,
  Radio,
  Stack,
  Spinner,
  useToast,
  VisuallyHidden,
  Box,
  Input,
  Select,
} from "@chakra-ui/react";
import { Minus, Plus } from "lucide-react";
import {
  doc,
  getDoc,
  collection,
  addDoc,
  updateDoc
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ContainerLayout from "../ContainerLayout";
import { useUserContext } from "../context/UserContext";
import PlacesAutocomplete from "react-places-autocomplete";
import useGroupMembers from "../hooks/useGroupMembers";
import useGroup from "../hooks/useGroup";

const colorOptions = [
  { value: "red", label: "Red", color: "#C51215" },
  { value: "yellow", label: "Yellow", color: "yellow.200" },
  { value: "black", label: "Black", color: "grey.900" },
  { value: "blue", label: "Blue", color: "#0B96D2" },
  { value: "green", label: "Green", color: "lightGreen.300" },
];

function CreateMatchForm() {
  const { groupId, matchId } = useParams();
  const { currentUser } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const { group } = useGroup(groupId);
  const { groupMembers} = useGroupMembers(groupId);

  const [playersPerTeam, setPlayersPerTeam] = useState({ team1: 5, team2: 5 });
  
  const [team1Players, setTeam1Players] = useState([]);
  const [team2Players, setTeam2Players] = useState([]);
  const [team1Color, setTeam1Color] = useState("yellow");
  const [team2Color, setTeam2Color] = useState("black");
  const [locationField, setLocationField] = useState("");
  const [city, setCity] = useState("");
  const [scheduledTime, setScheduledTime] = useState("");

  const [selectedPlayers, setSelectedPlayers] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const [matchVisibility, setMatchVisibility] = useState("private");

  // Check if this is an edit or create mode
  const isEditMode = Boolean(matchId);

  // Load existing match data in edit mode
  useEffect(() => {
    const fetchMatchData = async () => {
      if (matchId) {
        try {
          setLoading(true);
          const matchRef = doc(db, "groups", groupId, "matches", matchId);
          const matchSnap = await getDoc(matchRef);
  
          if (matchSnap.exists()) {
            const matchData = matchSnap.data();
  
            setTeam1Players(
              (matchData.players?.team1 || []).map((player) => ({
                uid: player.uid || null,
                firstName: player.name?.split(" ")[0] || player.firstName || "Guest",
                lastName: player.name?.split(" ")[1] || player.lastName || "",
                name: player.name || null,
              }))
            );
  
            setTeam2Players(
              (matchData.players?.team2 || []).map((player) => ({
                uid: player.uid || null,
                firstName: player.name?.split(" ")[0] || player.firstName || "Guest",
                lastName: player.name?.split(" ")[1] || player.lastName || "",
                name: player.name || null,
              }))
            );
  
            setTeam1Color(matchData.teamColors?.team1 || "yellow");
            setTeam2Color(matchData.teamColors?.team2 || "black");
            setLocationField(matchData.location || "");
            setCity(matchData.city || "");
            setScheduledTime(matchData.scheduledTime || "");
            setPlayersPerTeam({
              team1: matchData.playerCount?.team1 || 5,
              team2: matchData.playerCount?.team2 || 5,
            });
  
            setMatchVisibility(matchData.public ? "public" : "private");
          }
        } catch (error) {
          console.error("Error fetching match data:", error.message);
        } finally {
          setLoading(false);
        }
      }
    };
  
    fetchMatchData();
  }, [groupId, matchId]);
  
  
  
  // Effect for calculating the next scheduled date (only in creation mode)
  useEffect(() => {
    const fetchGroupDetails = async () => {
      try {
        setLoading(true);
        const groupRef = doc(db, "groups", groupId);
        const groupSnap = await getDoc(groupRef);
  
        if (groupSnap.exists() && !isEditMode) {
          const groupData = groupSnap.data();
          
          console.log("Raw group data:", groupData); // Ajoutez ce log
          const scheduledDay = groupData.scheduledDay;
          const groupScheduledTime = groupData.scheduledTime;
          if (!isEditMode && groupData.location) {
            setLocationField(groupData.location);
          }
          if (!scheduledDay || !groupScheduledTime) {
            console.warn("Invalid scheduledDay or scheduledTime:", scheduledDay, groupScheduledTime);
            return; // Évitez les calculs avec des données invalides
          }
  
          if (!scheduledTime) {
            const nextScheduledDate = calculateNextScheduledDate(
              scheduledDay,
              groupScheduledTime
            );
            console.log("Next scheduled date:", nextScheduledDate); // Vérifiez ici
            setScheduledTime(nextScheduledDate);
          }
        }
      } catch (error) {
        console.error("Error fetching group details:", error.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchGroupDetails();
  }, [groupId, scheduledTime, isEditMode]);
  
  
  

  // Fonction utilitaire pour calculer le prochain jour programmé
  const calculateNextScheduledDate = (scheduledDay, scheduledTime) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
  
    if (!scheduledDay || !scheduledTime) {
      console.error("Invalid scheduledDay or scheduledTime provided:", {
        scheduledDay,
        scheduledTime,
      });
      return null;
    }
  
    const today = new Date();
    const currentDayIndex = today.getDay();
    const targetDayIndex = daysOfWeek.indexOf(scheduledDay);
  
    if (targetDayIndex === -1) {
      console.error("Invalid scheduledDay:", scheduledDay);
      return null; // Invalid day
    }
  
    // Décomposer l'heure et les minutes de `scheduledTime`
    const [hours, minutes] = scheduledTime.split(":").map(Number);
  
    // Vérification si le jour cible est aujourd'hui
    if (currentDayIndex === targetDayIndex) {
      const now = new Date();
      const targetTime = new Date(now);
      targetTime.setHours(hours, minutes, 0, 0);
  
      // Si l'heure prévue est dans le futur aujourd'hui, retourner aujourd'hui
      if (now <= targetTime) {
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        const hour = String(hours).padStart(2, "0");
        const minute = String(minutes).padStart(2, "0");
        return `${year}-${month}-${day}T${hour}:${minute}`;
      }
    }
  
    // Calculer les jours jusqu'au prochain jour prévu
    const daysUntilTarget = (targetDayIndex - currentDayIndex + 7) % 7 || 7;
  
    // Calculer la date cible
    const nextDate = new Date(today);
    nextDate.setDate(today.getDate() + daysUntilTarget);
    nextDate.setHours(hours, minutes, 0, 0);
  
    // Reformater pour `datetime-local`
    const year = nextDate.getFullYear();
    const month = String(nextDate.getMonth() + 1).padStart(2, "0");
    const day = String(nextDate.getDate()).padStart(2, "0");
    const hour = String(nextDate.getHours()).padStart(2, "0");
    const minute = String(nextDate.getMinutes()).padStart(2, "0");
  
    return `${year}-${month}-${day}T${hour}:${minute}`;
  };
  
  
  
  
  
  



  useEffect(() => {
    const initializeCity = async () => {
      if (!locationField) return;
  
      // Fetch group details to check if the location matches the group's location
      try {
        const groupRef = doc(db, "groups", groupId);
        const groupSnap = await getDoc(groupRef);
  
        if (groupSnap.exists()) {
          const groupData = groupSnap.data();
  
          if (locationField === groupData.location) {
            setCity(groupData.city || ""); // Use the group's city
            console.log("Initialized city from group data:", groupData.city);
            return;
          }
        }
  
        // Use geocoding to fetch the city if location doesn't match group location
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: locationField }, (results, status) => {
          if (status === "OK" && results[0]) {
            const extractedCity = results[0].address_components.find((component) =>
              component.types.includes("locality")
            )?.long_name;
  
            if (extractedCity) {
              setCity(extractedCity);
              console.log("Initialized city using geocoder:", extractedCity);
            } else {
              console.warn("Geocoder did not find a city.");
              setCity(""); // Save an empty string if city is not found
            }
          } else {
            console.error("Geocode failed during initialization:", status);
            setCity(""); // Save an empty string on geocode failure
          }
        });
      } catch (error) {
        console.error("Error initializing city:", error);
        setCity(""); // Save an empty string on error
      }
    };
  
    initializeCity();
  }, [locationField, groupId]); // Run whenever locationField or groupId changes


  const handleSaveMatch = async () => {
    if (!scheduledTime || !locationField) {
      alert("Scheduled time or location is invalid.");
      return;
    }
  
    // Transform players to only include uid, firstName, and lastName
    const transformPlayers = (players) =>
      players
        .filter((p) => p) // Exclude null values
        .map((p) => ({
          uid: p.uid || null, // Include uid for registered players or null for guests
          name: p.firstName && p.lastName ? `${p.firstName} ${p.lastName}` : p.name || "Guest", // Fallback for guest names
        }));
  
    const transformedTeam1Players = transformPlayers(team1Players);
    const transformedTeam2Players = transformPlayers(team2Players);
  
    // Gather playerIds for reference
    const playerIds = [
      ...transformedTeam1Players.map((p) => p.uid).filter((uid) => uid),
      ...transformedTeam2Players.map((p) => p.uid).filter((uid) => uid),
    ];
  
    if (playerIds.length === 0) {
      alert("No players selected for the match.");
      return;
    }
  
    const matchData = {
      players: {
        team1: transformedTeam1Players, // Save only formatted team1 players
        team2: transformedTeam2Players, // Save only formatted team2 players
      },
      score: { team1: 0, team2: 0 },
      teamColors: {
        team1: team1Color,
        team2: team2Color,
      },
      location: locationField,
      city: city,
      scheduledTime,
      createdAt: new Date(),
      createdBy: currentUser.id,
      groupId: groupId,
      groupName: group.name,
      playerCount: {
        team1: playersPerTeam.team1,
        team2: playersPerTeam.team2,
      },
      playerIds,
      public: matchVisibility === "public",
    };
  
    try {
      if (matchId) {
        const matchRef = doc(db, "groups", groupId, "matches", matchId);
        await updateDoc(matchRef, matchData);
        navigate(`/groups/${groupId}/match/${matchId}`);
      } else {
        const matchesRef = collection(db, "groups", groupId, "matches");
        const newMatchRef = await addDoc(matchesRef, matchData);
        navigate(`/groups/${groupId}/match/${newMatchRef.id}`);
      }
    } catch (error) {
      console.error("Error saving match:", error.message);
      alert("Failed to save the match.");
    }
  };
  
  

  const addPlayerToTeam = (player) => {
    if (selectedPlayers.has(player.uid)) return;
  
    // Vérifier si le joueur est déjà dans une des équipes
    if (
      team1Players.some((p) => p?.uid === player.uid) ||
      team2Players.some((p) => p?.uid === player.uid)
    ) {
      return;
    }
  
    // Ajouter au premier slot disponible dans Team 1
    const team1Index = team1Players.findIndex((p) => p === null);
    if (team1Index !== -1) {
      const updatedTeam1 = [...team1Players];
      updatedTeam1[team1Index] = player;
      setTeam1Players(updatedTeam1);
      setSelectedPlayers((prev) => new Set(prev).add(player.uid));
      return;
    }
  
    // Ajouter au premier slot disponible dans Team 2
    const team2Index = team2Players.findIndex((p) => p === null);
    if (team2Index !== -1) {
      const updatedTeam2 = [...team2Players];
      updatedTeam2[team2Index] = player;
      setTeam2Players(updatedTeam2);
      setSelectedPlayers((prev) => new Set(prev).add(player.uid));
      return;
    }
  
    // Si les deux équipes sont pleines
    alert("Both teams are full!");
  };
  

  const removePlayerFromTeam = (team, index) => {
    const setTeamPlayers = team === "team1" ? setTeam1Players : setTeam2Players;
    const teamPlayers = team === "team1" ? team1Players : team2Players;
  
    const removedPlayer = teamPlayers[index];
    if (removedPlayer) {
      setSelectedPlayers((prev) => {
        const updatedSet = new Set(prev);
        updatedSet.delete(removedPlayer.uid);
        return updatedSet;
      });
    }
  
    const updatedPlayers = [...teamPlayers];
    updatedPlayers[index] = null;
    setTeamPlayers(updatedPlayers);
  };
  

  const addGuestPlayer = (team, index, name) => {
    if (!name) {
      alert("Name cannot be empty.");
      return;
    }
  
    if (isGuestNameDuplicate(name)) {
      alert("A guest with this name already exists!");
      return;
    }
  
    const setTeamPlayers = team === "team1" ? setTeam1Players : setTeam2Players;
    const teamPlayers = team === "team1" ? team1Players : team2Players;
  
    const updatedPlayers = [...teamPlayers];
    updatedPlayers[index] = { uid: null, name }; // Ajouter le joueur invité
    setTeamPlayers(updatedPlayers);
  };
  

  useEffect(() => {
    setTeam1Players((prev) =>
      Array.from({ length: playersPerTeam.team1 }, (_, i) => prev[i] || null)
    );
    setTeam2Players((prev) =>
      Array.from({ length: playersPerTeam.team2 }, (_, i) => prev[i] || null)
    );
  }, [playersPerTeam]);
  

  const isGuestNameDuplicate = (name) => {
    return (
      team1Players.some((player) => player?.name === name) ||
      team2Players.some((player) => player?.name === name)
    );
  };

  const handleSelect = async (address) => {
    try {
      setLocationField(address);
  
      // Fetch group details
      const groupRef = doc(db, "groups", groupId);
      const groupSnap = await getDoc(groupRef);
  
      if (groupSnap.exists()) {
        const groupData = groupSnap.data();
  
        // If the selected location matches the group's location
        if (address === groupData.location) {
          if (groupData.city) {
            setCity(groupData.city); // Use the group's city
            console.log("Using group city:", groupData.city);
          } else {
            console.warn("Group city is missing, saving as empty string.");
            setCity(""); // Save an empty string if group city is missing
          }
          return;
        }
      }
  
      // If the address is different or group doesn't exist, use geocoder
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status) => {
        if (status === "OK" && results[0]) {
          const extractedCity = results[0].address_components.find((component) =>
            component.types.includes("locality")
          )?.long_name;
  
          if (extractedCity) {
            setCity(extractedCity);
            console.log("Geocoded City:", extractedCity);
          } else {
            console.warn("Geocoder did not find a city, saving as empty string.");
            setCity(""); // Save an empty string if city is not found
          }
        } else {
          console.error("Geocode failed:", status);
          setCity(""); // Save an empty string on geocode failure
          toast({
            title: "Geocode Error",
            description: `Failed to geocode address. Status: ${status}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      });
    } catch (error) {
      console.error("Error in geocoding or fetching group data:", error);
      setCity(""); // Save an empty string on error
      toast({
        title: "Error",
        description: "An unexpected error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  
  

  if (loading) {
    return (
      <ContainerLayout>
        <Flex justify="center" align="center" h="100%">
          <Spinner size="xl" />
        </Flex>
      </ContainerLayout>
    );
  }

  const renderColorOptions = (teamColor, setTeamColor) => (
    <RadioGroup value={teamColor} onChange={setTeamColor}>
      <Stack direction="row" spacing={{ base: 2, md: 4 }}>
        {colorOptions.map((color) => (
          <label key={color.value} style={{ cursor: "pointer" }}>
            <VisuallyHidden>
              <Radio value={color.value} />
            </VisuallyHidden>
            <Flex
              w={{ base: 12, md: 16 }}
              h={{ base: 12, md: 16 }}
              borderRadius={8}
              p={1}
              border="2px solid"
              borderColor={teamColor === color.value ? "gray.300" : "gray.700"}
              transition="border-color 0.2s">
              <Box
                bg={color.color}
                height={"100%"}
                w={"100%"}
                borderRadius={4}
              />
            </Flex>
          </label>
        ))}
      </Stack>
    </RadioGroup>
  );

  return (
      <Flex direction="column" gap={8}>
        <Flex direction={{ base: "column", md: "row" }} gap={8}>
          <Flex direction="column" gap={2} flex={1}>
            <Heading fontSize="sm">Location</Heading>
            <PlacesAutocomplete
              value={locationField}
              onChange={setLocationField}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <Input
                    {...getInputProps({
                      placeholder: "Enter a location",
                      className: "location-search-input",
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <Spinner size="sm" />}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      const style = suggestion.active
                        ? { backgroundColor: "#0D121C", cursor: "pointer", padding: "4px 8px"}
                        : { backgroundColor: '#202939', cursor: "pointer", padding: "4px 8px" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                          key={suggestion.placeId}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            
           
          </Flex>

          {/* Scheduled Time */}
          <Flex direction="column" gap={2} w={{ base: "100%", md: "240px" }}>
            <Heading fontSize="sm">Scheduled Time</Heading>
            <Input
              type="datetime-local"
              value={scheduledTime}
              onChange={(e) => setScheduledTime(e.target.value)}
            />
          </Flex>

          <Flex direction="column" gap={2} w={{ base: "100%", md: "240px" }}>
          <Heading fontSize="sm">Visibility</Heading>
          <Select
            value={matchVisibility}
            onChange={(e) => setMatchVisibility(e.target.value)}
          >
            <option value="private">Private</option>
            <option value="public">Public</option>
          </Select>
        </Flex>

        </Flex>

        <Flex direction={{ base: "column", md: "row" }} gap={8}>
          {/* Players per team */}

          <Flex direction={{ base: "column", md: "row" }} gap={8}>
    {/* Players Team 1 */}
    <Flex direction="column" gap={4}>
      <Heading fontSize="sm">Players Team 1</Heading>
      <Flex align="center" gap={4} bg={"grey.900"} p={2}>
        <IconButton
          onClick={() =>
            setPlayersPerTeam((prev) => ({
              ...prev,
              team1: Math.max(prev.team1 - 1, 2),
            }))
          }
          variant="ghost"
          bg={"pink.400"}
          icon={<Minus />}
        />
        <Heading fontSize="3xl" px={2}>{playersPerTeam.team1}</Heading>
        <IconButton
          onClick={() =>
            setPlayersPerTeam((prev) => ({
              ...prev,
              team1: Math.min(prev.team1 + 1, 11),
            }))
          }
          variant="ghost"
          bg={"pink.400"}
          icon={<Plus />}
        />
      </Flex>
    </Flex>

    {/* Players Team 2 */}
    <Flex direction="column" gap={4}>
      <Heading fontSize="sm">Players Team 2</Heading>
      <Flex align="center" gap={4} bg={"grey.900"} p={2}>
        <IconButton
          onClick={() =>
            setPlayersPerTeam((prev) => ({
              ...prev,
              team2: Math.max(prev.team2 - 1, 2),
            }))
          }
          variant="ghost"
          bg={"pink.400"}
          icon={<Minus />}
        />
        <Heading fontSize="3xl" px={2}>{playersPerTeam.team2}</Heading>
        <IconButton
          onClick={() =>
            setPlayersPerTeam((prev) => ({
              ...prev,
              team2: Math.min(prev.team2 + 1, 11),
            }))
          }
          variant="ghost"
          bg={"pink.400"}
          icon={<Plus />}
        />
      </Flex>
    </Flex>
  </Flex>

          {/* Group Members */}
          <Flex direction="column" gap={4} flex={1}>
            <Heading fontSize="sm">Group members</Heading>
            <Flex wrap="wrap" gap={2}>
  {groupMembers.map((member) => (
    <Button
      key={member.uid}
      size="sm"
      colorScheme={
        selectedPlayers.has(member.uid) ||
        team1Players.some((p) => p?.uid === member.uid) ||
        team2Players.some((p) => p?.uid === member.uid)
          ? "gray"
          : "blue"
      }
      onClick={() => addPlayerToTeam(member)}
      isDisabled={
        selectedPlayers.has(member.uid) ||
        team1Players.some((p) => p?.uid === member.uid) ||
        team2Players.some((p) => p?.uid === member.uid)
      }
    >
      {member.firstName} {member.lastName}
    </Button>
  ))}
</Flex>
          </Flex>
        </Flex>

        {/* Teams */}
        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={{ base: 8, md: 16 }}>
  <GridItem as={Flex} gap={4} flexDirection={"column"}>
    <Heading fontSize="lg">Team 1</Heading>
    {renderColorOptions(team1Color, setTeam1Color)}
    <Flex direction="column" gap={2}>
      {team1Players.map((player, index) => (
        <Button
          key={`team1-${index}`}
          variant="ghost"
          bg={"grey.800"}
          colorScheme={player ? "red" : "green"}
          onClick={() =>
            player
              ? removePlayerFromTeam("team1", index)
              : addGuestPlayer("team1", index, prompt("Enter guest name"))
          }
        >
          {player
            ? `${player.firstName || ""} ${player.lastName || ""}`.trim() || player.name || `Position ${index + 1}`
            : `Position ${index + 1}`}
        </Button>
      ))}
    </Flex>
  </GridItem>

  <GridItem as={Flex} gap={4} flexDirection={"column"}>
    <Heading fontSize="lg">Team 2</Heading>
    {renderColorOptions(team2Color, setTeam2Color)}
    <Flex direction="column" gap={2}>
      {team2Players.map((player, index) => (
        <Button
          key={`team2-${index}`}
          variant="ghost"
          bg={"grey.800"}
          colorScheme={player ? "red" : "green"}
          onClick={() =>
            player
              ? removePlayerFromTeam("team2", index)
              : addGuestPlayer("team2", index, prompt("Enter guest name"))
          }
        >
          {player
            ? `${player.firstName || ""} ${player.lastName || ""}`.trim() || player.name || `Position ${index + 1}`
            : `Position ${index + 1}`}
        </Button>
      ))}
    </Flex>
  </GridItem>
</Grid>


        {/* Save Match */}
        <Box
        position={"sticky"}
        bottom={0}
        p={4}
        bg={"grey.950"}
        w={"100%"}
        >
          <Button
          w={"100%"}
         
          bg={"lightGreen.300"}
          
          onClick={handleSaveMatch}
          isDisabled={
            !team1Color ||
            !team2Color ||
            !location ||
            !scheduledTime
          }>
          {matchId ? "Update Match" : "Create Match"}
        </Button>
        </Box>
      </Flex>
  );
}

export default CreateMatchForm;
