import { useState, useEffect } from "react";
import { doc, onSnapshot, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";

/**
 * Custom hook to fetch and manage group members in real-time.
 * @param {string} groupId - The ID of the group.
 */
const useGroupMembers = (groupId) => {
  const [groupMembers, setGroupMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!groupId) {
      setError("Group ID is required.");
      setLoading(false);
      return;
    }

    let unsubscribeGroup = null;

    const fetchGroupMembers = async () => {
      try {
        setLoading(true);

        // Real-time listener for the group document
        const groupRef = doc(db, "groups", groupId);
        unsubscribeGroup = onSnapshot(groupRef, async (groupSnap) => {
          if (!groupSnap.exists()) {
            setError("Group not found.");
            setGroupMembers([]);
            setLoading(false);
            return;
          }

          const groupData = groupSnap.data();
          const memberIds = groupData.members || [];

          if (memberIds.length === 0) {
            setGroupMembers([]);
            setLoading(false);
            return;
          }

          // Fetch member data for all member IDs
          const membersData = await Promise.all(
            memberIds.map(async (uid) => {
              const memberRef = doc(db, "players", uid);
              const memberSnap = await getDoc(memberRef);
              if (memberSnap.exists()) {
                const data = memberSnap.data();
                return {
                  id: uid,
                  value: uid, // For use in dropdowns
                  label: `${data.firstName || ""} ${data.lastName || ""}`.trim(), // Combine firstName and lastName
                  ...data,
                };
              }
              return null; // Handle missing members gracefully
            })
          );

          // Filter out null values (members not found in the database)
          setGroupMembers(membersData.filter((member) => member !== null));
          setLoading(false);
        });
      } catch (err) {
        console.error("Error fetching group members:", err.message);
        setError("Failed to fetch group members.");
        setLoading(false);
      }
    };

    fetchGroupMembers();

    return () => {
      // Cleanup Firestore listeners
      if (unsubscribeGroup) unsubscribeGroup();
    };
  }, [groupId]);

  return { groupMembers, setGroupMembers, loading, error };
};

export default useGroupMembers;
