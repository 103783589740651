import {
  Flex,
  Heading,
  Button,
  Tag,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Center,
  Spinner,
  chakra,
  IconButton,
  MenuGroup,
  MenuDivider,
} from "@chakra-ui/react";
import { NavLink as Link, useNavigate } from "react-router-dom";
import useGroup from "../../hooks/useGroup";
import { auth } from "../../firebaseConfig";
import { EllipsisVertical } from "lucide-react";

function GroupHeader({ groupId, handleOpenEditModal }) {
  const { group, loading, error } = useGroup(groupId);
  const navigate = useNavigate();

  const handleInvitePlayers = () => {
    const message = encodeURIComponent(
      `Hello! Join our group "${group.name}" on Futix.app! Use the code "${group.code}" to join. Here's the link: https://futix.app`
    );
    const whatsappUrl = `https://wa.me/?text=${message}`;
    window.open(whatsappUrl, "_blank");
  };

 // Check if the current user is an admin
  const currentUserUid = auth.currentUser?.uid;
  const isAdmin = group?.admin?.includes(currentUserUid);


  if (loading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center h="100vh">
        <Heading color="red.500">Error: {error}</Heading>
      </Center>
    );
  }

  if (!group) {
    return (
      <Center h="100vh">
        <Heading color="gray.500">Group not found</Heading>
      </Center>
    );
  }



  return (
  

      <Flex direction={"column"} gap={8}>
        <Flex
          justify="space-between"
          align={{ base: "flex-start", md: "flex-start" }}
          direction={{ base: "column", md: "row" }}
          gap={8}>
          <Flex
            gap={{ base: 4, md: 8 }}
            align={{ base: "flex-start", md: "center" }}>
            <Flex direction={"column"} gap={4}>
              <Heading fontSize={{ base: "52px", md: "62px" }} lineHeight={0.85}>
                {group.name}
              </Heading>
              <HStack>
              <Tag size="lg" colorScheme="lightGreen" w={"fit-content"}>
                {group.code}
              </Tag>

              </HStack>
             
            </Flex>
          </Flex>

          <HStack>
           

           
            <Button
              onClick={() => navigate(`/groups/${groupId}/create-match`)}
              bg="lightGreen.300">
              Create match
            </Button>
            
            {isAdmin && (
              <Menu>
                <MenuButton as={IconButton} variant={"outline"} size={"lg"} icon={<EllipsisVertical />} />
                  <MenuList>
                    <MenuItem onClick={handleOpenEditModal}>Edit group</MenuItem>
                    <MenuItem color={"red.500"}>Quit group</MenuItem>
                  
                  {isAdmin && (
                    <>  <MenuDivider />
                    <MenuGroup title='Invite players'>
                    <MenuItem onClick={handleInvitePlayers}>Whatsapp</MenuItem>
                  </MenuGroup></>
                )}
                  </MenuList>
              </Menu>
            )}
          </HStack>
        </Flex>

        <Flex
          px={{ base: 0, md: 8 }}
          bg={"grey.800"}

          justifyContent={{ base: "center", md: "flex-start" }}
          sx={{
            "a": {
                color: "grey.400",
                transition: "all 0.2s ease-in-out",
            },
            "a:hover": {
                color: "white",
            },
            "a.active": {
                bg: "grey.950",
                color: "white",
            }
          }}>
          <Flex
            as={Link}
            to={`/groups/${groupId}`}
            end
            py={{ base: 3, md: 4 }}
            px={{ base: 4, md: 8 }}
            borderRadius={"0"}
            _selected={{
              bg: "grey.950",
              border: "none",
            }}>
            <Heading fontSize={"xl"}>Matches</Heading>
          </Flex>

          <Flex
            as={Link}
            to={`/groups/${groupId}/stats`}
            py={{ base: 3, md: 4 }}
            px={{ base: 4, md: 8 }}
            borderRadius={"0"}
            _selected={{
              bg: "grey.950",
              border: "none",
            }}>
            <Heading fontSize={"xl"}>
              <chakra.span display={{ base: "none", md: "inline" }}>
                Player{" "}
              </chakra.span>
              Stats
            </Heading>
          </Flex>

          <Flex
            as={Link}
            to={`/groups/${groupId}/squad`}
            py={{ base: 3, md: 4 }}
            px={{ base: 4, md: 8 }}
            borderRadius={"0"}
            _selected={{
              bg: "grey.950",
              border: "none",
            }}>
            <Heading fontSize={"xl"}>Squad</Heading>
          </Flex>
        </Flex>
      </Flex>

    
  );
}

export default GroupHeader;
