import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Text,
  Badge,
  TableContainer,
  Heading,
  chakra,
  Avatar,
} from "@chakra-ui/react";
import { useGroupContext } from "../../context/GroupContext";
import useGroupMembers from "../../hooks/useGroupMembers";

function GroupStatistics() {
  const { groupId, openPlayerModal } = useGroupContext();
  const { groupMembers } = useGroupMembers(groupId);
  const [playerStats, setPlayerStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({
    key: "presence",
    direction: "desc",
  });

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "groups", groupId, "matches"),
      async (matchesSnapshot) => {
        const matchesList = matchesSnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter((match) => match.startTime && match.endTime);

        const playersCollectionRef = collection(db, "players");
        const playersSnapshot = await getDocs(playersCollectionRef);

        const playersMap = {};
        playersSnapshot.forEach((doc) => {
          const playerData = doc.data();
          playersMap[playerData.uid] =
            playerData.profileImage || playerData.photoURL;
        });

        const combinedStats = {};

        matchesList.forEach((match) => {
          const allPlayers = [
            ...(match.players?.team1 || []),
            ...(match.players?.team2 || []),
          ];

          allPlayers.forEach((player) => {
            const key = player.uid || `${player.name}-${player.team}`;
            if (!combinedStats[key]) {
              combinedStats[key] = {
                uid: player.uid || null,
                firstName: player.name || "Guest",
                lastName: "",
                image: playersMap[player.uid] || player.photoURL || null,
                matchesPlayed: 0,
                goals: 0,
                wins: 0,
                draws: 0,
                losses: 0,
                isGuest: !player.uid, // Identifie si le joueur est un invité
              };
            }

            combinedStats[key].matchesPlayed += 1;
            combinedStats[key].goals += player.goals || 0;

            const isInTeam1 = match.players?.team1.some(
              (p) =>
                (p.uid && p.uid === player.uid) ||
                (!p.uid && p.name === player.name)
            );
            const isInTeam2 = match.players?.team2.some(
              (p) =>
                (p.uid && p.uid === player.uid) ||
                (!p.uid && p.name === player.name)
            );

            if (isInTeam1 && match.score?.team1 > match.score?.team2) {
              combinedStats[key].wins += 1;
            } else if (isInTeam2 && match.score?.team2 > match.score?.team1) {
              combinedStats[key].wins += 1;
            } else if (
              (isInTeam1 || isInTeam2) &&
              match.score?.team1 === match.score?.team2
            ) {
              combinedStats[key].draws += 1;
            } else if (isInTeam1 || isInTeam2) {
              combinedStats[key].losses += 1;
            }
          });
        });

        const totalMatches = matchesList.length;

        // Transform combinedStats into an array and compute additional stats
        const finalStats = Object.values(combinedStats).map((player) => ({
          ...player,
          presence:
            totalMatches > 0
              ? ((player.matchesPlayed / totalMatches) * 100).toFixed(2)
              : "0.00",
          winRatio:
            player.matchesPlayed > 0
              ? ((player.wins / player.matchesPlayed) * 100).toFixed(2)
              : "0.00",
          goalsPerMatch:
            player.matchesPlayed > 0
              ? (player.goals / player.matchesPlayed).toFixed(2)
              : "0.00",
          record: `${player.wins}-${player.draws}-${player.losses}`,
        }));

        // Ajouter les membres qui n'ont jamais joué
        const statsWithInactiveMembers = groupMembers.map((member) => {
          const existingPlayer = finalStats.find(
            (stat) => stat.uid === member.uid
          );
          if (existingPlayer) return existingPlayer;

          return {
            uid: member.uid,
            firstName: member.firstName || "Unknown",
            lastName: member.lastName || "",
            image: member.profileImage || "",
            matchesPlayed: 0,
            goals: 0,
            wins: 0,
            draws: 0,
            losses: 0,
            presence: "0.00",
            winRatio: "0.00",
            goalsPerMatch: "0.00",
            record: "0-0-0",
            isGuest: false,
          };
        });

        // Combine both stats
        const combinedWithGuests = [
          ...statsWithInactiveMembers,
          ...finalStats.filter((stat) => stat.isGuest),
        ];

        setPlayerStats(combinedWithGuests.sort((a, b) => b.goals - a.goals));
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [groupId, groupMembers]);

  const handleSort = (key) => {
    let direction = "desc";
    if (sortConfig.key === key && sortConfig.direction === "desc") {
      direction = "asc";
    }
    setSortConfig({ key, direction });
  
    const sortedData = [...playerStats].sort((a, b) => {
      const aValue = key === "winRatio" || key === "presence" ? parseFloat(a[key]) : a[key];
      const bValue = key === "winRatio" || key === "presence" ? parseFloat(b[key]) : b[key];
  
      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });
  
    setPlayerStats(sortedData);
  };
  

  const getColorByPercentage = (value) => {
    if (value >= 75) return "green";
    if (value >= 50) return "yellow";
    if (value >= 25) return "orange";
    return "red";
  };

  if (loading) {
    return <Text>Loading...</Text>;
  }

  return (
    <Flex direction="column" gap={4}>
      {playerStats.length > 0 ? (
        <TableContainer style={{ scrollbarWidth: "none" }}>
          <Table
            size={{ base: "sm", md: "md" }}
            sx={{
              "tr > th:first-of-type": {
                pr: 0,
              },
              "tr > td:first-of-type": {
                pr: 0,
              },
               "tr:hover > td" : {
                bg: "grey.950 !important"
              },
            }}>
            <Thead>
              <Tr>
                <Th w={{ base: "29.45px", md: "63px" }}
                  position="sticky"
                  left={0}
                  bg="grey.950"
                  >#</Th>
                <Th
                position="sticky"
                left={{ base: "29.45px", md: "63px" }}
                zIndex={1}
                bg="grey.950"
                >Player</Th>
                <Th
                  isNumeric
                  w={"7%"}
                  onClick={() => handleSort("matchesPlayed")}
                  bg={
                    sortConfig.key === "matchesPlayed"
                      ? "grey.900"
                      : "transparent"
                  }
                  color={
                    sortConfig.key === "matchesPlayed" ? "white" : "grey.500"
                  }>
                  M
                  <chakra.span display={{ base: "none", md: "inline" }}>
                    atches
                  </chakra.span>
                </Th>
                <Th
                  isNumeric
                  w={"7%"}
                  onClick={() => handleSort("goals")}
                  bg={sortConfig.key === "goals" ? "grey.900" : "transparent"}
                  color={sortConfig.key === "goals" ? "white" : "grey.500"}>
                  G
                  <chakra.span display={{ base: "none", md: "inline" }}>
                    oals
                  </chakra.span>
                </Th>
                <Th
                  isNumeric
                  w={"7%"}
                  onClick={() => handleSort("goalsPerMatch")}
                  bg={
                    sortConfig.key === "goalsPerMatch"
                      ? "grey.900"
                      : "transparent"
                  }
                  color={
                    sortConfig.key === "goalsPerMatch" ? "white" : "grey.500"
                  }>
                  G/M
                </Th>
                <Th
                  isNumeric
                  w={"7%"}
                  onClick={() => handleSort("winRatio")}
                  bg={
                    sortConfig.key === "winRatio" ? "grey.900" : "transparent"
                  }
                  color={sortConfig.key === "winRatio" ? "white" : "grey.500"}>
                  W
                  <chakra.span display={{ base: "none", md: "inline" }}>
                    in{" "}
                  </chakra.span>
                  R
                  <chakra.span display={{ base: "none", md: "inline" }}>
                    atio
                  </chakra.span>
                </Th>
                <Th
                  isNumeric
                  w={"7%"}
                  onClick={() => handleSort("record")}
                  bg={sortConfig.key === "record" ? "grey.900" : "transparent"}
                  color={sortConfig.key === "record" ? "white" : "grey.500"}>
                  W-D-L
                </Th>
                <Th
                  isNumeric
                  w={"7%"}
                  onClick={() => handleSort("presence")}
                  bg={
                    sortConfig.key === "presence" ? "grey.900" : "transparent"
                  }
                  color={sortConfig.key === "presence" ? "white" : "grey.500"}>
                  % P
                  <chakra.span display={{ base: "none", md: "inline" }}>
                    resence
                  </chakra.span>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {playerStats.map((player, index) => (
                <Tr
                  key={index}
                  onClick={() => player.uid && openPlayerModal(player.uid)}
                  bg={"grey.800"}
                  borderBottom={"2px solid"}
                  borderColor={"grey.950"}
                  cursor={player.uid ? "pointer" : "default"}
                  _hover={{ bg: "grey.900" }}>
                   <Td
                      w={{ base: "29.45px", md: "63px" }}
                      position="sticky"
                      left={{ base: 0, md: 0 }}
                      zIndex={1}
                      bg="grey.800"
                    >
                      <Heading size={"xs"} color={"grey.500"}>
                        {index + 1}
                      </Heading>
                    </Td>
                    <Td
                      position="sticky"
                      left={{ base: "29.45px", md: "63px" }}
                      zIndex={1}
                      bg="grey.800"
                    >
                    <Flex align="center" gap={{ base: 2, md: 4 }}>
                      <Avatar
                        src={player.image || player.photoURL}
                        alt={`${player.firstName} ${player.lastName}`}
                        borderRadius="full"
                        boxSize="29.45px"
                        aspectRatio={"1 / 1"}
                        w={"32px"}
                      />
                      <Text fontWeight={"500"} color={"white"}>
                        {player.firstName} {player.lastName}
                      </Text>
                    </Flex>
                  </Td>
                  <Td
                    isNumeric
                    w={"7%"}
                    bg={
                      sortConfig.key === "matchesPlayed"
                        ? "grey.900"
                        : "transparent"
                    }
                    fontWeight={
                      sortConfig.key === "matchesPlayed" ? "600" : "normal"
                    }
                    color={
                      sortConfig.key === "matchesPlayed" ? "white" : "grey.500"
                    }>
                    {player.matchesPlayed}
                  </Td>
                  <Td
                    isNumeric
                    w={"7%"}
                    bg={sortConfig.key === "goals" ? "grey.900" : "transparent"}
                    fontWeight={sortConfig.key === "goals" ? "600" : "normal"}
                    color={sortConfig.key === "goals" ? "white" : "grey.500"}>
                    {player.goals}
                  </Td>
                  <Td
                    isNumeric
                    w={"7%"}
                    bg={
                      sortConfig.key === "goalsPerMatch"
                        ? "grey.900"
                        : "transparent"
                    }
                    fontWeight={
                      sortConfig.key === "goalsPerMatch" ? "500" : "normal"
                    }
                    color={
                      sortConfig.key === "goalsPerMatch" ? "white" : "grey.500"
                    }>
                    {player.goalsPerMatch}
                  </Td>
                  <Td
                    isNumeric
                    w={"7%"}
                    bg={
                      sortConfig.key === "winRatio" ? "grey.900" : "transparent"
                    }
                    $
                    fontWeight={
                      sortConfig.key === "winRatio" ? "500" : "normal"
                    }
                    color={
                      sortConfig.key === "winRatio" ? "white" : "grey.500"
                    }>
                    <Badge colorScheme={getColorByPercentage(player.winRatio)}>
                      {player.winRatio}%
                    </Badge>
                  </Td>
                  <Td
                    isNumeric
                    w={"7%"}
                    bg={
                      sortConfig.key === "record" ? "grey.900" : "transparent"
                    }
                    fontWeight={sortConfig.key === "record" ? "500" : "normal"}
                    color={sortConfig.key === "record" ? "white" : "grey.500"}>
                    {player.record}
                  </Td>
                  <Td
                    isNumeric
                    w={"7%"}
                    bg={
                      sortConfig.key === "presence" ? "grey.900" : "transparent"
                    }
                    fontWeight={
                      sortConfig.key === "presence" ? "500" : "normal"
                    }
                    color={
                      sortConfig.key === "presence" ? "white" : "grey.500"
                    }>
                    <Badge colorScheme={getColorByPercentage(player.presence)}>
                      {player.presence}%
                    </Badge>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Flex flex={1} justify={"center"} align={"center"}>
          <Text size={"md"}>No statistics available.</Text>
        </Flex>
      )}
    </Flex>
  );
}

export default GroupStatistics;
