import React, { useState, useEffect } from "react";
import {
  Grid,
  Flex,
  Heading,
  Text,
  IconButton,
  Spinner,
} from "@chakra-ui/react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import usePlayerMatches from "../hooks/usePlayerMatches";
import useGroupMatches from "../hooks/useGroupMatches";
import MatchListing from "./MatchListing";

function Calendar({ groupId, playerId, grid, showCompletedOnly = false }) {
  const [matches, setMatches] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const { matches: groupMatches, loading: groupLoading } =
    useGroupMatches(groupId);
  const { matches: playerMatches, loading: playerLoading } =
    usePlayerMatches(playerId);

  // Calcul explicite du loading
  const loading =
    (groupId && groupLoading) ||
    (playerId && playerLoading) ||
    (!groupId && !playerId);

  useEffect(() => {
    if (!loading) {
      let relevantMatches = [];

      if (groupId && !playerId && groupMatches) {
        relevantMatches = groupMatches;
      } else if (playerId && !groupId && playerMatches) {
        relevantMatches = playerMatches;
      } else if (groupId && playerId) {
        const mergedMatches = [...groupMatches, ...playerMatches];
        const uniqueMatches = Array.from(
          new Map(mergedMatches.map((match) => [match.id, match])).values()
        );
        relevantMatches = uniqueMatches;
      }

      // Filtrer les matchs basés sur la prop `showCompletedOnly`
      if (showCompletedOnly) {
        relevantMatches = relevantMatches.filter((match) => match.endTime);
      }

      setMatches(relevantMatches);
    }
  }, [groupId, playerId, groupMatches, playerMatches, loading, showCompletedOnly]);

  const handleRemoveMatch = (matchId) => {
    setMatches((prevMatches) =>
      prevMatches.filter((match) => match.id !== matchId)
    );
  };

  // Changer le mois (incrément/décrément)
  const changeMonth = (direction) => {
    const newDate = new Date(currentMonth);
    newDate.setMonth(newDate.getMonth() + direction);
    setCurrentMonth(newDate);
  };

  // Filtrer les matches en fonction du mois actuel
  const filteredMatches = matches
    .filter((match) => {
      const matchDate = new Date(match.scheduledTime || match.startTime);
      return (
        matchDate.getFullYear() === currentMonth.getFullYear() &&
        matchDate.getMonth() === currentMonth.getMonth()
      );
    })
    .sort((a, b) => {
      const dateA = new Date(a.scheduledTime || a.startTime);
      const dateB = new Date(b.scheduledTime || b.startTime);
      return dateB - dateA;
    });

  // Formater le mois et l'année
  const formatMonthYear = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" h="100%">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap={4} w={"100%"}>
      <Flex
        justify="space-between"
        align="center"
        bg="grey.900"
        p={2}
        w={{ base: "100%", md: "fit-content" }}>
        <IconButton
          aria-label="Previous month"
          icon={<ChevronLeft />}
          onClick={() => changeMonth(-1)}
          bg="pink.400"
          color="white"
          borderRadius={2}
          _hover={{ bg: "pink.500" }}
        />
        <Heading fontSize="lg" color="white" px={16}>
          {formatMonthYear(currentMonth)}
        </Heading>
        <IconButton
          aria-label="Next month"
          icon={<ChevronRight />}
          borderRadius={2}
          onClick={() => changeMonth(1)}
          bg="pink.400"
          color="white"
          _hover={{ bg: "pink.500" }}
        />
      </Flex>

      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: `repeat(${grid}, 1fr)` }}
        gap={4}>
        {filteredMatches.length > 0 ? (
          filteredMatches.map((match) => (
            <MatchListing
              key={match.id}
              match={match}
              playerId={playerId}
              onRemoveMatch={handleRemoveMatch}
            />
          ))
        ) : (
          <Text fontSize="lg" color="grey.500" textAlign="center">
            No matches found for this month.
          </Text>
        )}
      </Grid>
    </Flex>
  );
}

export default Calendar;
