import React, { useState } from "react";
import { Flex, Image, Button, FormControl, Input, useToast } from "@chakra-ui/react";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage"; // Firebase Storage SDK

const ProfileImageUploader = ({ value, onChange, storagePath }) => {
  const [image, setImage] = useState(value); // Image actuelle ou URL
  const [uploading, setUploading] = useState(false); // Gérer l'état de chargement
  const toast = useToast();

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading(true);
    try {
      const storage = getStorage();
      const imageRef = ref(storage, storagePath); // Chemin de stockage Firebase

      // Téléchargement du fichier
      await uploadBytes(imageRef, file);

      // Obtenir l'URL publique
      const downloadURL = await getDownloadURL(imageRef);

      setImage(downloadURL); // Mettre à jour l'aperçu local avec l'URL
      onChange(downloadURL); // Passer l'URL au composant parent

      toast({
        title: "Image Uploaded",
        description: "The image has been successfully uploaded.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error uploading image:", error.message);
      toast({
        title: "Error",
        description: "Failed to upload the image. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setUploading(false);
    }
  };

  const handleDeleteImage = async () => {
    if (!storagePath) {
      console.error("Storage path is missing");
      toast({
        title: "Error",
        description: "Failed to delete image. Storage path not provided.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const storage = getStorage();
      const imageRef = ref(storage, storagePath);

      // Supprimer l'image de Firebase Storage
      await deleteObject(imageRef);

      setImage(null);
      onChange(""); // Supprimer l'URL dans le parent

      toast({
        title: "Image Deleted",
        description: "The image has been successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting image:", error.message);
      toast({
        title: "Error",
        description: "Failed to delete the image. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <FormControl mb={4}>
        {image ? (
          <Flex direction="row" align="center" gap={8}>
            <Image
              src={image}
              alt="Profile Logo"
              boxSize={{ base: "62px", md: "92px" }}
              borderRadius="md"
              objectFit="cover"
              bg="grey.700"
            />
            <Flex gap={2}>
              <Button
                variant="outline"
                size={{ base: "md", md: "lg" }}
                onClick={() => document.getElementById("fileInput").click()}
                isDisabled={uploading}
              >
                Replace image
              </Button>
              <Button
                colorScheme="red"
                size={{ base: "md", md: "lg" }}
                onClick={handleDeleteImage}
                isDisabled={uploading}
              >
                Delete image
              </Button>
            </Flex>
          </Flex>
        ) : (
          <Button
            onClick={() => document.getElementById("fileInput").click()}
            isDisabled={uploading}
          >
            {uploading ? "Uploading..." : "Upload image"}
          </Button>
        )}
        <Input
          id="fileInput"
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          style={{ display: "none" }}
        />
    </FormControl>
  );
};

export default ProfileImageUploader;
