import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Flex, useBreakpointValue } from "@chakra-ui/react";
import Header from "./components/navigation/Header";
import ContainerLayout from "./ContainerLayout";
import MobileNavigation from "./components/navigation/MobileNavigation";
import EditProfileDrawer from "./components/EditProfileDrawer";
import { useUserContext } from "./context/UserContext";
import { auth } from "./firebaseConfig"; // Import your Firebase auth instance
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

function BackendLayout({ groupName, matchId }) {
  const { currentUser } = useUserContext();
  
  const isMobile = useBreakpointValue({ base: true, md: false });

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleOpenDrawer = () => setIsDrawerOpen(true);
  const handleCloseDrawer = () => setIsDrawerOpen(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth); // Firebase sign-out
      navigate("/"); // Redirect to the login page
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  };

  return (
   <>
    <ContainerLayout>
      
      <Flex
        direction="column"
        minH={{ base: "calc(100vh - 79px)", md: "100vh" }}
        py={{ base: 4, md: 8 }}
        gap={{ base: 4, md: 8 }}
        bg={"grey.950"}
        sx={{
          th: {
            fontSize: "10px",
          },
        }}>
        
        {!isMobile && <Header groupName={groupName} matchId={matchId} handleOpenDrawer={handleOpenDrawer} handleLogout={handleLogout} />}
        
        <Outlet />
      
      </Flex>
      
    
    </ContainerLayout>
  
  {isMobile && <MobileNavigation handleOpenDrawer={handleOpenDrawer} />}

      <EditProfileDrawer
        isOpen={isDrawerOpen}
        onOpen={handleOpenDrawer}
        onClose={handleCloseDrawer}
        userId={currentUser?.id}
        handleLogout={handleLogout}
      />

      
  
  </> 
  );
}

export default BackendLayout;
