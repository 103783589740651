import {
  Flex,


  Spinner,

  Avatar,
} from "@chakra-ui/react";
import { Link, NavLink } from "react-router-dom";
import { useUserContext } from "../../context/UserContext";
import placeholder from "../../img/playerProfilePlaceholder.jpg"
import { CalendarDays, Search, ShieldHalf } from "lucide-react";

function MobileNavigation({ handleOpenDrawer}) {
  const { currentUser, loading } = useUserContext();
  
  
  if (loading) {
    return (
      <Flex justify="center" align="center" h="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Flex
    position="fixed" 
    bottom={0}
    zIndex={2}
    left={0}
    w={"100%"}
    margin="auto"
    right={0}
    bg='grey.800'
    justifyContent="center"
    px={0}
    py={0}
    sx={{
      "a": {
        color: "grey.400",
        fontSize: "11px",
        fontWeight: "500",
        letterSpacing: "-.02em",
        lineHeight: "1",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textTransform: "capitalize",
        whiteSpace: "nowrap",
        display: "flex",
        flex: 1,
        maxWidth: "80px",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        px: 4,
        pt:3,
        pb:6,
      },
      "a .lucide": {
        color: "grey.300",
      },
      "a.active": {
        color: "white",
      },
      "a.active .lucide": {
        color: "lightGreen.300",
      },
    }}
    >
        

        <Flex align="center" gap={1} direction={"column"} as={NavLink} to="/profile" end>
            <ShieldHalf />
            Groups
        </Flex>

        <Flex align="center" gap={1} direction={"column"} as={NavLink} to="/profile/calendar">
            <CalendarDays />
            Calendar
        </Flex>

        <Flex align="center" gap={1} direction={"column"} as={NavLink} to="/find">
            <Search />
            Find
        </Flex>

        <Flex align="center" gap={1} direction={"column"} as={Link} onClick={handleOpenDrawer}>
        <Avatar
              src={currentUser?.profileImage || placeholder}
              alt="Profile"
              borderRadius={"full"}
              w="24px"
              h="24px"
              justifyContent={"center"}
            />
            Profile
        </Flex>
        


      </Flex>
  );
}

export default MobileNavigation;
