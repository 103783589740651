import React, { useEffect } from "react";
import {
  Flex,
  Button,
  Heading,
  Text,
  Box,
  IconButton,
  VStack,
} from "@chakra-ui/react";
import Goal from "../icons/Goal";

function ScoreAggregatorButtons({
  players,
  isMatchEnded,
  isRunning,
  isAuthorized,
  pendingGoals,
  setPendingGoals,
  pendingOwnGoals,
  setPendingOwnGoals,
  cooldowns,
  addGoal,
  cancelPendingGoal,
  cancelPendingOwnGoal,
  addOwnGoal,
}) {
  // Décompte pour les buts en attente
  useEffect(() => {
    const interval = setInterval(() => {
      setPendingGoals((prev) => {
        const updated = { ...prev };
        Object.keys(updated).forEach((key) => {
          if (updated[key].counter > 0) {
            updated[key] = {
              ...updated[key],
              counter: updated[key].counter - 1,
            };
          }
        });
        return updated;
      });
      setPendingOwnGoals((prev) => {
        const updated = { ...prev };
        Object.keys(updated).forEach((key) => {
          if (updated[key].counter > 0) {
            updated[key] = {
              ...updated[key],
              counter: updated[key].counter - 1,
            };
          }
        });
        return updated;
      });
    }, 1000); // Décompte chaque seconde

    return () => clearInterval(interval);
  }, [setPendingGoals, setPendingOwnGoals]);

  // Extraire le prénom du joueur
  const firstName = (player) => player.name?.split(" ")[0] || player.name;

  const GoalsScored = ({ player, team }) => (
    <>
      {player.goals > 0 ? (
        <Flex
          gap={0.5}
          align={"center"}
          direction={team === 1 ? "row" : "row-reverse"}>
          <Text fontSize={"md"} color="grey.400">{player.goals}</Text> <Goal />
        </Flex>
      ) : (
        ""
      )}
    </>
  );

  return (
    <Flex
      direction="row"
      justify="space-around"
      align="flex-start"
      gap={1}
      w="100%"
      flex={1}>
      
      
      {/* Équipe 1 */}
      <Flex direction="column" align="center" flex="1" gap={1}>
        {players?.team1?.map((player, index) => {
          const key = `team1-${player.uid || player.name}`;
          return (
            <Flex key={index} direction="column" gap={2} w="100%">
              <Button
                bg={"grey.800"}
                _hover={{ bg: "grey.900" }}
                isDisabled={
                  isMatchEnded ||
                  !isRunning ||
                  isAuthorized === false ||
                  cooldowns[key]
                }
                pointerEvents={isMatchEnded || !isRunning ? "none" : "auto"}
                opacity={isMatchEnded ? "1 !important" : "inherit"}
                borderRadius={0}
                w={"100%"}
                justifyContent={"flex-start"}
                gap={2}
                px={2}
                py={8}
                onClick={() => addGoal("team1", player)}>
                <Flex w={"100%"} justifyContent={"space-between"}>
                  {!isMatchEnded && (
                    <IconButton
                      size="sm"
                      bg="red.600"
                      _hover={{ bg: "red.900" }}
                      aria-label="Own Goal"
                      icon={
                        <VStack gap={0}>
                          <Heading size={"2xs"} lineHeight={1} color={"white"}>
                            Own
                          </Heading>
                          <Heading size={"2xs"} lineHeight={1} color={"white"}>
                            Goal
                          </Heading>
                        </VStack>
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        addOwnGoal("team1", player);
                      }}
                    />
                  )}

                  <Flex
                    color={"white"}
                    fontSize={"xl"}
                    gap={{ base: 2, md: 4 }}
                    justify={"flex-end"}
                    w={"100%"}
                    align={"center"}>
                    <Heading as={Flex} color={"white"} fontSize={"xl"} gap={2}>
                      {firstName(player)}
                    </Heading>
                    <GoalsScored player={player} team={1} />
                  </Flex>
                </Flex>
              </Button>
              {pendingGoals[key] && pendingGoals[key].counter >= 0 && (
                <Box
                  position="fixed"
                  bottom={0}
                  bg="grey.950"
                  w="100%"
                  left={0}
                  zIndex={99999}
                  p={4}>
                  <Button
                    colorScheme="red"
                    w={"100%"}
                    position="relative"
                    overflow="hidden"
                    onClick={() => cancelPendingGoal(key)}
                    _before={{
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: "100%",
                      width: `${(pendingGoals[key].counter / 10) * 100}%`,
                      bg: "rgba(255, 0, 0, 0.5)",
                      transition: "width 1s linear",
                    }}>
                    Cancel goal ({pendingGoals[key].counter})
                  </Button>
                </Box>
              )}

              {pendingOwnGoals[key] && pendingOwnGoals[key].counter >= 0 && (
                <Box
                  position="fixed"
                  bottom={0}
                  bg="grey.950"
                  w="100%"
                  left={0}
                  zIndex={99999}
                  p={4}>
                  <Button
                    colorScheme="red"
                    w={"100%"}
                    position="relative"
                    overflow="hidden"
                    onClick={() => cancelPendingOwnGoal(key)}
                    _before={{
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: "100%",
                      width: `${(pendingOwnGoals[key].counter / 10) * 100}%`,
                      bg: "rgba(255, 0, 0, 0.5)",
                      transition: "width 1s linear",
                    }}>
                    Cancel own goal ({pendingOwnGoals[key].counter})
                  </Button>
                </Box>
              )}
            </Flex>
          );
        })}
      </Flex>

      {/* Équipe 2 */}
      <Flex direction="column" align="center" flex="1" gap={1}>
        {players?.team2?.map((player, index) => {
          const key = `team2-${player.uid || player.name}`;
          return (
            <Flex key={index} direction="column" gap={2} w="100%">
              <Button
                bg={"grey.800"}
                _hover={{ bg: "grey.900" }}
                isDisabled={
                  isMatchEnded ||
                  !isRunning ||
                  isAuthorized === false ||
                  cooldowns[key]
                }
                pointerEvents={isMatchEnded || !isRunning ? "none" : "auto"}
                opacity={isMatchEnded ? "1 !important" : "inherit"}
                borderRadius={0}
                w={"100%"}
                justifyContent={"flex-start"}
                gap={2}
                px={2}
                py={8}
                onClick={() => addGoal("team2", player)}>
                <Flex w={"100%"} justifyContent={"space-between"}>
                  <Flex
                    color={"white"}
                    fontSize={"xl"}
                    gap={{ base: 2, md: 4 }}
                    justify={"flex-start"}
                    w={"100%"}
                    align={"center"}>
                    <GoalsScored player={player} team={2} />
                    <Heading as={Flex} color={"white"} fontSize={"xl"} gap={2}>
                      {firstName(player)}
                    </Heading>
                  </Flex>
                  {/* Bouton OG pour les buts contre leur propre équipe */}

                  {!isMatchEnded && (
                    <IconButton
                      size="sm"
                      bg="red.600"
                      _hover={{ bg: "red.900" }}
                      aria-label="Own Goal"
                      icon={
                        <VStack gap={0}>
                          <Heading size={"2xs"} lineHeight={1} color={"white"}>
                            Own
                          </Heading>
                          <Heading size={"2xs"} lineHeight={1} color={"white"}>
                            Goal
                          </Heading>
                        </VStack>
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        addOwnGoal("team2", player);
                      }}
                    />
                  )}
                </Flex>
              </Button>
              {pendingGoals[key] && pendingGoals[key].counter >= 0 && (
                <Box
                  position="fixed"
                  bottom={0}
                  bg="grey.950"
                  w="100%"
                  left={0}
                  zIndex={99999}
                  p={4}>
                  <Button
                    colorScheme="red"
                    w={"100%"}
                    position="relative"
                    overflow="hidden"
                    onClick={() => cancelPendingGoal(key)}
                    _before={{
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: "100%",
                      width: `${(pendingGoals[key].counter / 10) * 100}%`,
                      bg: "rgba(255, 0, 0, 0.5)",
                      transition: "width 1s linear",
                    }}>
                    Cancel goal ({pendingGoals[key].counter})
                  </Button>
                </Box>
              )}

              {pendingOwnGoals[key] && pendingOwnGoals[key].counter >= 0 && (
                <Box
                  position="fixed"
                  bottom={0}
                  bg="grey.950"
                  w="100%"
                  left={0}
                  zIndex={99999}
                  p={4}>
                  <Button
                    colorScheme="red"
                    w={"100%"}
                    position="relative"
                    overflow="hidden"
                    onClick={() => cancelPendingOwnGoal(key)}
                    _before={{
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: "100%",
                      width: `${(pendingOwnGoals[key].counter / 10) * 100}%`,
                      bg: "rgba(255, 0, 0, 0.5)",
                      transition: "width 1s linear",
                    }}>
                    Cancel own goal ({pendingOwnGoals[key].counter})
                  </Button>
                </Box>
              )}
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
}

export default ScoreAggregatorButtons;
