import { Container, Flex } from "@chakra-ui/react";

function ContainerLayout({children}) {
  return (
    <Container as={Flex} maxW="container.xl" flexDirection={"column"} px={{ base: 4, md: 8 }} pb={{ base: 20, md: 0 }}>
      {children}
    </Container>
  );
}

export default ContainerLayout;
