import { Flex } from "@chakra-ui/react";
import TeamLineup from "./TeamLineup";

function Lineups({ players, openPlayerModal }) {
  return (
    <Flex
      direction="row"
      justify="space-around"
      align="flex-start"
      gap={1}
      w="100%"
    >
      {/* Team 1 */}
      <TeamLineup
        teamPlayers={players?.team1}
        justifyContent="flex-end"
        openPlayerModal={openPlayerModal}
      />

      {/* Team 2 */}
      <TeamLineup
        teamPlayers={players?.team2}
        justifyContent="flex-start"
        openPlayerModal={openPlayerModal}
      />
    </Flex>
  );
}

export default Lineups;
