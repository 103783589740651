import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";

/**
 * Custom hook to fetch and manage group data from Firestore.
 * @param {string} groupId - The ID of the group to fetch.
 */
const useGroup = (groupId) => {
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchGroup = async () => {
    if (!groupId) return;

    setLoading(true);
    setError(null);

    try {
      const groupRef = doc(db, "groups", groupId);
      const groupSnap = await getDoc(groupRef);

      if (groupSnap.exists()) {
        setGroup({ id: groupSnap.id, ...groupSnap.data() });
      } else {
        console.warn(`Group with ID "${groupId}" does not exist.`);
        setGroup(null);
        setError("Group not found.");
      }
    } catch (err) {
      console.error("Error fetching group data:", err.message);
      setError("Failed to fetch group data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGroup();
  }, [groupId]);

  return { group, loading, error, reloadGroup: fetchGroup };
};

export default useGroup;
