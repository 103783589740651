// src/context/UserContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { auth, db } from "../firebaseConfig"; // Firebase auth instance
import { collection, query, where, getDocs, updateDoc, doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

// Create the context
const UserContext = createContext();

// Provider component
export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      setLoading(true);
      if (firebaseUser) {
        try {
          const userRef = doc(db, "players", firebaseUser.uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            setCurrentUser({ id: firebaseUser.uid, ...userSnap.data() });
          } else {
            setCurrentUser(null);
          }
        } catch (error) {
          console.error("Error fetching user data:", error.message);
          setCurrentUser(null);
        }
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);
  
  const updateUserProfile = async (updatedData) => {
    if (!currentUser?.id) return;
  
    const userId = currentUser.id;
    const newName = `${updatedData.firstName} ${updatedData.lastName}`; // Combine firstName et lastName
  
    try {
   
     // Mise à jour du profil utilisateur dans "players"
      const userRef = doc(db, "players", userId);
      await updateDoc(userRef, updatedData);
      setCurrentUser((prev) => ({ ...prev, ...updatedData }));
  
      // Récupérer tous les groupes où l'utilisateur est membre
      const groupsQuery = query(
        collection(db, "groups"),
        where("members", "array-contains", userId)
      );
      const groupsSnapshot = await getDocs(groupsQuery);
  
      const updatePromises = []; // Liste des opérations asynchrones
  
      // Parcourir chaque groupe
      for (const groupDoc of groupsSnapshot.docs) {
        const groupId = groupDoc.id;
  
        // Récupérer tous les matchs de la sous-collection "matches"
        const matchesRef = collection(db, "groups", groupId, "matches");
        const matchesSnapshot = await getDocs(matchesRef);
  
        // Parcourir chaque match
        for (const matchDoc of matchesSnapshot.docs) {
          const matchData = matchDoc.data();
          const matchRef = doc(db, "groups", groupId, "matches", matchDoc.id);
  
          let isUpdated = false;
  
          // Mise à jour des joueurs dans team1
          matchData.players.team1 = matchData.players.team1.map((player) => {
            if (player.uid === userId) {
              isUpdated = true;
              return { ...player, name: newName }; // Mettre à jour le champ name
            }
            return player; // Garder le joueur tel quel
          });
  
          // Mise à jour des joueurs dans team2
          matchData.players.team2 = matchData.players.team2.map((player) => {
            if (player.uid === userId) {
              isUpdated = true;
              return { ...player, name: newName }; // Mettre à jour le champ name
            }
            return player; // Garder le joueur tel quel
          });
  
          // Si une mise à jour est nécessaire, ajouter à la liste des promesses
          if (isUpdated) {
            updatePromises.push(updateDoc(matchRef, { players: matchData.players }));
          }
        }
      }
  
      // Exécuter toutes les mises à jour en parallèle
      await Promise.all(updatePromises);
  
      console.log("User profile and matches updated successfully!");
    } catch (error) {
      console.error("Error updating user profile and matches:", error.message);
    }
  };

  

  return (
    <UserContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        loading,
        updateUserProfile,
        groups,
        setGroups
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Hook to use the UserContext
export const useUserContext = () => useContext(UserContext);
